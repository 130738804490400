import { useEffect } from 'react';
import { Link } from 'react-router-dom';

export default function Terms() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 text-gray-100">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <h1 className="text-4xl font-bold mb-8">Terms of Service</h1>
        
        <div className="space-y-8">
          <section>
            <h2 className="text-2xl font-semibold mb-4">Agreement to Terms</h2>
            <p className="text-gray-300">
              By accessing or using Gravy's services, you agree to be bound by these Terms of Service and all applicable laws and regulations. If you do not agree with any of these terms, you are prohibited from using or accessing our services.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">Use of Services</h2>
            <div className="space-y-4">
              <p className="text-gray-300">
                Our services are designed to help businesses analyse and optimise their fresh produce costs. By using our services, you agree to:
              </p>
              <ul className="list-disc list-inside text-gray-300 space-y-2">
                <li>Provide accurate and complete information</li>
                <li>Maintain the security of your account</li>
                <li>Use the services only for lawful purposes</li>
                <li>Not interfere with the proper working of the services</li>
              </ul>
            </div>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">Intellectual Property</h2>
            <p className="text-gray-300 mb-4">
              The services and all related content, features, and functionality are owned by Gravy and are protected by international copyright, trademark, and other intellectual property laws.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">User Data</h2>
            <div className="space-y-4">
              <p className="text-gray-300">
                You retain all rights to your data. By using our services, you grant us a licence to:
              </p>
              <ul className="list-disc list-inside text-gray-300 space-y-2">
                <li>Process and analyse your invoice data</li>
                <li>Generate insights and recommendations</li>
                <li>Improve our services using anonymised data</li>
              </ul>
            </div>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">Limitation of Liability</h2>
            <p className="text-gray-300">
              Gravy shall not be liable for any indirect, incidental, special, consequential, or punitive damages resulting from your use of or inability to use the services.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">Modifications to Service</h2>
            <p className="text-gray-300">
              We reserve the right to modify or discontinue our services at any time without notice. We shall not be liable to you or any third party for any modification, suspension, or discontinuance of the services.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">Governing Law</h2>
            <p className="text-gray-300">
              These Terms shall be governed by and construed in accordance with the laws of England and Wales, without regard to its conflict of law provisions.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">Contact</h2>
            <p className="text-gray-300">
              For any questions about these Terms of Service, please{' '}
              <Link to="/contact" className="text-accent-primary hover:text-accent-primary/90">
                contact us
              </Link>.
            </p>
          </section>
        </div>
      </div>
    </div>
  );
}