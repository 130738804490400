import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../lib/supabase';
import { EnvelopeIcon, ArrowPathIcon } from '@heroicons/react/24/outline';

export default function EmailVerification({ email }: { email: string }) {
  const navigate = useNavigate();
  const [isChecking, setIsChecking] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const checkVerification = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        
        // If no session, user needs to verify email
        if (!session) return;

        // Get user details
        const { data: { user }, error: userError } = await supabase.auth.getUser();
        
        if (userError) throw userError;
        
        // If user is confirmed, create company profile and redirect
        if (user?.email_confirmed_at) {
          try {
            // Create company profile
            const { error: companyError } = await supabase
              .from('companies')
              .insert([
                {
                  name: user.user_metadata.company_name,
                  user_id: user.id
                }
              ])
              .select()
              .single();

            if (companyError) throw companyError;

            // Redirect to dashboard
            navigate('/dashboard');
          } catch (err) {
            console.error('Error creating company profile:', err);
            setError('Failed to set up your company profile. Please try again.');
          }
        }
      } catch (err) {
        console.error('Error checking verification:', err);
      }
    };

    // Check immediately
    checkVerification();

    // Set up interval to check every 5 seconds
    const interval = setInterval(checkVerification, 5000);

    return () => clearInterval(interval);
  }, [navigate]);

  const handleResendEmail = async () => {
    setIsChecking(true);
    setError('');

    try {
      const { error } = await supabase.auth.resend({
        type: 'signup',
        email,
      });

      if (error) throw error;

      alert('Verification email resent successfully!');
    } catch (err) {
      console.error('Error resending verification:', err);
      setError('Failed to resend verification email. Please try again.');
    } finally {
      setIsChecking(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center px-4">
      <div className="max-w-md w-full text-center">
        <div className="bg-white p-8 rounded-xl shadow-xl">
          <div className="mb-6 flex justify-center">
            <div className="p-4 bg-blue-100 rounded-full">
              <EnvelopeIcon className="h-12 w-12 text-blue-600" />
            </div>
          </div>

          <h1 className="text-2xl font-bold text-gray-900 mb-4">
            Check Your Email
          </h1>

          <p className="text-gray-600 mb-6">
            We've sent a verification link to <strong>{email}</strong>. Click the link to verify your email address and access your dashboard.
          </p>

          <div className="space-y-4">
            <button
              onClick={handleResendEmail}
              disabled={isChecking}
              className="w-full flex items-center justify-center gap-2 bg-blue-600 text-white py-3 rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isChecking ? (
                <>
                  <ArrowPathIcon className="h-5 w-5 animate-spin" />
                  Sending...
                </>
              ) : (
                'Resend Verification Email'
              )}
            </button>

            {error && (
              <div className="p-4 bg-red-50 border border-red-200 rounded-lg text-red-600">
                {error}
              </div>
            )}
          </div>

          <div className="mt-8 text-sm text-gray-500">
            <p>Can't find the email? Check your spam folder or click the resend button above.</p>
          </div>
        </div>
      </div>
    </div>
  );
}