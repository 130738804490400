import { ArrowUpIcon, ArrowDownIcon } from '@heroicons/react/24/outline';

interface StatCardProps {
  title: string;
  value: string | number;
  change?: number;
  icon: React.ReactNode;
}

export function StatCard({ title, value, change, icon }: StatCardProps) {
  return (
    <div className="stat-card glass-card rounded-xl p-6 transition-all">
      <div className="flex items-center justify-between">
        <div>
          <p className="text-sm font-medium text-gray-500 mb-1">{title}</p>
          <p className="text-2xl font-bold gradient-text">{value}</p>
          {change !== undefined && (
            <div className="flex items-center mt-2">
              {change >= 0 ? (
                <ArrowUpIcon className="h-4 w-4 text-green-500 mr-1 animated-icon" />
              ) : (
                <ArrowDownIcon className="h-4 w-4 text-red-500 mr-1 animated-icon" />
              )}
              <span className={`text-sm ${change >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                {Math.abs(change)}%
              </span>
            </div>
          )}
        </div>
        <div className="p-3 bg-gradient-to-br from-blue-50 to-blue-100 rounded-xl">
          <div className="animated-icon">
            {icon}
          </div>
        </div>
      </div>
    </div>
  );
}