import { ShieldCheckIcon, HeartIcon, ClockIcon } from '@heroicons/react/24/outline';

interface BenefitsProps {
  scrollToForm: () => void;
}

export function Benefits({ scrollToForm }: BenefitsProps) {
  return (
    <section className="py-20 bg-gray-800">
      <div className="container mx-auto px-6">
        <div>
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold text-gray-100 mb-4">
              Why Gravy?
            </h2>
            <p className="text-xl text-gray-300">
              Food costs keep rising. Your profits don't have to shrink.
            </p>
          </div>
          
          <div className="grid md:grid-cols-3 gap-12">
            <div className="text-center bg-gray-700/80 p-8 rounded-xl shadow-lg">
              <div className="mb-6 flex justify-center">
                <div className="p-4 bg-accent-primary/10 rounded-full">
                  <ShieldCheckIcon className="h-12 w-12 text-accent-primary" />
                </div>
              </div>
              <h3 className="text-xl font-semibold mb-4 text-gray-100">Find out if you're overpaying</h3>
              <p className="text-gray-400">
                Instantly compare your fresh produce prices to market rates.
              </p>
            </div>

            <div className="text-center bg-gray-700/80 p-8 rounded-xl shadow-lg">
              <div className="mb-6 flex justify-center">
                <div className="p-4 bg-accent-secondary/10 rounded-full">
                  <HeartIcon className="h-12 w-12 text-accent-secondary" />
                </div>
              </div>
              <h3 className="text-xl font-semibold mb-4 text-gray-100">Keep your suppliers, pay fair prices</h3>
              <p className="text-gray-400">
                Gravy helps you negotiate smarter without switching.
              </p>
            </div>

            <div className="text-center bg-gray-700/80 p-8 rounded-xl shadow-lg">
              <div className="mb-6 flex justify-center">
                <div className="p-4 bg-accent-success/10 rounded-full">
                  <ClockIcon className="h-12 w-12 text-accent-success" />
                </div>
              </div>
              <h3 className="text-xl font-semibold mb-4 text-gray-100">Save time, increase profits</h3>
              <p className="text-gray-400">
                No more chasing invoices or price-checking suppliers.
              </p>
            </div>
          </div>

          <div className="mt-16 text-center">
            <p className="text-2xl font-bold text-accent-success mb-8">
              94% of restaurant operators using Gravy uncover five-figure savings per site.
            </p>
            <button
              onClick={scrollToForm}
              className="try-it-free-button px-8 py-4 rounded-lg text-lg font-semibold text-white"
            >
              See your savings today—upload one invoice
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}