import { 
  DocumentCheckIcon,
  PresentationChartLineIcon,
  BanknotesIcon
} from '@heroicons/react/24/outline';

interface HowItWorksProps {
  scrollToForm: () => void;
}

export function HowItWorks({ scrollToForm }: HowItWorksProps) {
  const steps = [
    {
      icon: <DocumentCheckIcon className="h-8 w-8 text-accent-primary" />,
      title: "Upload an invoice",
      description: "We analyse your fruit & veg prices against market data."
    },
    {
      icon: <PresentationChartLineIcon className="h-8 w-8 text-accent-secondary" />,
      title: "See your savings instantly",
      description: "Get a breakdown of overpriced items & negotiation insights."
    },
    {
      icon: <BanknotesIcon className="h-8 w-8 text-accent-success" />,
      title: "Take action with confidence",
      description: "Keep your suppliers, but pay fair prices."
    }
  ];

  return (
    <section className="py-20 bg-gray-900">
      <div className="container mx-auto px-6">
        <h2 className="text-3xl font-bold text-center text-gray-100 mb-16">
          How It Works
        </h2>
        <div className="grid md:grid-cols-3 gap-8">
          {steps.map((step, index) => (
            <div key={index} className="text-center bg-gray-800 p-8 rounded-xl shadow-lg">
              <div className="mb-6 flex justify-center">
                <div className="p-4 bg-gray-700/50 rounded-full">
                  {step.icon}
                </div>
              </div>
              <h3 className="text-xl font-semibold mb-4 text-gray-100">{step.title}</h3>
              <p className="text-gray-400">{step.description}</p>
            </div>
          ))}
        </div>
        <div className="mt-12 text-center">
          <button
            onClick={scrollToForm}
            className="try-it-free-button px-8 py-4 rounded-lg text-lg font-semibold text-white"
          >
            Try Gravy for Free
          </button>
        </div>
      </div>
    </section>
  );
}