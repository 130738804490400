import { useState, useEffect } from 'react';
import { EnvelopeIcon, PhoneIcon, ClockIcon, MapPinIcon } from '@heroicons/react/24/outline';
import { submitToHubSpot } from '../lib/hubspot';

declare global {
  interface Window {
    Calendly?: CalendlyWidget;
  }
}

export default function Contact() {
  const [isCalendlyReady, setIsCalendlyReady] = useState(false);

  useEffect(() => {
    // Scroll to top on component mount
    window.scrollTo(0, 0);

    // Check if Calendly is already loaded
    if (window.Calendly) {
      setIsCalendlyReady(true);
      return;
    }

    // If not loaded, wait for it
    const checkCalendly = setInterval(() => {
      if (window.Calendly) {
        setIsCalendlyReady(true);
        clearInterval(checkCalendly);
      }
    }, 100);

    // Cleanup
    return () => clearInterval(checkCalendly);
  }, []);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    try {
      await submitToHubSpot({
        name,
        email,
        message,
        type: 'contact'
      });

      setSubmitted(true);
      setName('');
      setEmail('');
      setMessage('');
    } catch (error) {
      console.error('Error sending message:', error);
      alert('There was an error sending your message. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const openCalendly = (url: string) => {
    if (!isCalendlyReady) {
      console.log('Calendly is not ready yet');
      return;
    }

    window.Calendly?.initPopupWidget({
      url,
      prefill: {
        email,
        name
      }
    });

    // Ensure body scroll is disabled when Calendly is open
    document.body.style.overflow = 'hidden';

    // Add event listener for Calendly close
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList') {
          const removedNodes = Array.from(mutation.removedNodes);
          if (removedNodes.some(node => (node as Element).classList?.contains('calendly-popup-overlay'))) {
            document.body.style.overflow = 'auto';
            observer.disconnect();
          }
        }
      });
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true
    });
  };

  const contactInfo = [
    {
      icon: <EnvelopeIcon className="h-6 w-6" />,
      title: "Email",
      content: "inbox@trygravy.com",
      description: "Send us an email anytime",
      href: "mailto:inbox@trygravy.com"
    },
    {
      icon: <PhoneIcon className="h-6 w-6" />,
      title: "Phone Support",
      content: "Schedule a Call",
      description: "Book a 30-minute consultation",
      calendlyLink: "https://calendly.com/inbox-trygravy/30min"
    },
    {
      icon: <ClockIcon className="h-6 w-6" />,
      title: "Response Time",
      content: "Within 24 hours",
      description: "We aim to respond quickly"
    },
    {
      icon: <MapPinIcon className="h-6 w-6" />,
      title: "Location",
      content: "London, UK",
      description: "Serving restaurants nationwide"
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800">
      {/* Hero Section */}
      <div className="relative py-24 px-4 sm:px-6 lg:px-8">
        <div className="absolute inset-0 bg-gradient-to-b from-accent-primary/20 to-transparent"></div>
        <div className="relative max-w-7xl mx-auto text-center">
          <h1 className="text-4xl md:text-5xl font-bold text-gray-100 mb-6">
            Get in Touch
          </h1>
          <p className="text-xl text-gray-300 max-w-3xl mx-auto">
            Whether you have questions about pricing, features, or anything else, our team is ready to help you make the most of Gravy.
          </p>
        </div>
      </div>

      {/* Contact Info Grid */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 -mt-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {contactInfo.map((info, index) => (
            <div
              key={index}
              className="bg-gray-800/50 backdrop-blur-sm p-6 rounded-xl border border-gray-700 hover:border-accent-primary/50 transition-all duration-300"
            >
              <div className="flex items-start space-x-4">
                <div className="p-3 bg-accent-primary/10 rounded-lg text-accent-primary">
                  {info.icon}
                </div>
                <div>
                  <h3 className="text-lg font-semibold text-gray-100 mb-1">{info.title}</h3>
                  {info.calendlyLink ? (
                    <button
                      onClick={() => openCalendly(info.calendlyLink!)}
                      className="text-accent-primary hover:text-accent-primary/90 font-medium cursor-pointer"
                    >
                      {info.content}
                    </button>
                  ) : info.href ? (
                    <a
                      href={info.href}
                      className="text-accent-primary hover:text-accent-primary/90 font-medium"
                    >
                      {info.content}
                    </a>
                  ) : (
                    <p className="text-gray-300">{info.content}</p>
                  )}
                  <p className="text-sm text-gray-400 mt-1">{info.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Contact Form Section */}
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
        <div className="bg-gray-800/50 backdrop-blur-sm rounded-2xl shadow-xl border border-gray-700 overflow-hidden">
          <div className="p-8 sm:p-12">
            {submitted ? (
              <div className="text-center py-8">
                <div className="mb-6">
                  <div className="mx-auto w-16 h-16 bg-accent-success/20 rounded-full flex items-center justify-center">
                    <svg className="w-8 h-8 text-accent-success" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                  </div>
                </div>
                <h2 className="text-2xl font-semibold text-gray-100 mb-4">
                  Message Sent Successfully
                </h2>
                <p className="text-gray-300 mb-8">
                  Thank you for reaching out. We'll get back to you within 24 hours.
                </p>
                <button
                  onClick={() => setSubmitted(false)}
                  className="bg-accent-primary text-white px-8 py-3 rounded-lg hover:bg-accent-primary/90 transition-colors"
                >
                  Send Another Message
                </button>
              </div>
            ) : (
              <>
                <div className="text-center mb-12">
                  <h2 className="text-3xl font-bold text-gray-100 mb-4">Send Us a Message</h2>
                  <p className="text-gray-300">
                    We'd love to hear from you. Fill out the form below and we'll get back to you shortly.
                  </p>
                </div>

                <form onSubmit={handleSubmit} className="space-y-6">
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                    <div>
                      <label htmlFor="name" className="block text-sm font-medium text-gray-300 mb-2">
                        Name
                      </label>
                      <input
                        type="text"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="w-full px-4 py-3 bg-gray-700/50 border border-gray-600 text-gray-100 rounded-lg focus:ring-2 focus:ring-accent-primary focus:border-transparent transition-all duration-200"
                        required
                      />
                    </div>

                    <div>
                      <label htmlFor="email" className="block text-sm font-medium text-gray-300 mb-2">
                        Email
                      </label>
                      <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full px-4 py-3 bg-gray-700/50 border border-gray-600 text-gray-100 rounded-lg focus:ring-2 focus:ring-accent-primary focus:border-transparent transition-all duration-200"
                        required
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="message" className="block text-sm font-medium text-gray-300 mb-2">
                      Message
                    </label>
                    <textarea
                      id="message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      rows={6}
                      className="w-full px-4 py-3 bg-gray-700/50 border border-gray-600 text-gray-100 rounded-lg focus:ring-2 focus:ring-accent-primary focus:border-transparent transition-all duration-200"
                      required
                    />
                  </div>

                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="w-full bg-gradient-to-r from-accent-primary to-accent-secondary text-white py-4 rounded-lg text-lg font-semibold hover:opacity-90 transition-all duration-200 transform hover:-translate-y-0.5 disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:translate-y-0"
                  >
                    {isSubmitting ? 'Sending...' : 'Send Message'}
                  </button>
                </form>
              </>
            )}
          </div>
        </div>

        {/* FAQ Section */}
        <div className="mt-24 text-center">
          <h2 className="text-3xl font-bold text-gray-100 mb-12">Frequently Asked Questions</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="text-left bg-gray-800/50 backdrop-blur-sm p-6 rounded-xl border border-gray-700">
              <h3 className="text-lg font-semibold text-gray-100 mb-3">How Quickly Can I Start Saving?</h3>
              <p className="text-gray-300">
                Most customers see potential savings within their first invoice analysis. The actual savings can be realized as soon as you implement our negotiation insights.
              </p>
            </div>
            <div className="text-left bg-gray-800/50 backdrop-blur-sm p-6 rounded-xl border border-gray-700">
              <h3 className="text-lg font-semibold text-gray-100 mb-3">Do I Need to Change Suppliers?</h3>
              <p className="text-gray-300">
                No! Gravy helps you negotiate better prices with your existing suppliers. We believe in maintaining strong supplier relationships while ensuring fair prices.
              </p>
            </div>
            <div className="text-left bg-gray-800/50 backdrop-blur-sm p-6 rounded-xl border border-gray-700">
              <h3 className="text-lg font-semibold text-gray-100 mb-3">What Types of Invoices Do You Analyse?</h3>
              <p className="text-gray-300">
                Currently, we specialize in fruit and vegetable invoices. This focused approach allows us to provide the most accurate and valuable insights in this category.
              </p>
            </div>
            <div className="text-left bg-gray-800/50 backdrop-blur-sm p-6 rounded-xl border border-gray-700">
              <h3 className="text-lg font-semibold text-gray-100 mb-3">Is My Data Secure?</h3>
              <p className="text-gray-300">
                Absolutely. We use enterprise-grade encryption and security measures to protect your data. Your information is never shared with third parties.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}