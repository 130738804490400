import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { supabase } from '../lib/supabase';
import { CheckCircleIcon, ArrowPathIcon, XCircleIcon } from '@heroicons/react/24/outline';

export default function VerifySuccess() {
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    // Fire LinkedIn conversion tracking
    if (window.lintrk) {
      window.lintrk('track', { conversion_id: 20131706 });
    }

    const setupUserProfile = async () => {
      try {
        let session;

        // First try to get the current session
        const { data: sessionData } = await supabase.auth.getSession();
        session = sessionData.session;

        // If no session, check URL parameters
        if (!session) {
          const params = new URLSearchParams(window.location.search);
          const accessToken = params.get('access_token');
          const refreshToken = params.get('refresh_token');
          const type = params.get('type');

          // Handle password reset flow
          if (type === 'recovery') {
            navigate('/reset-password');
            return;
          }

          // Try to set session from tokens if available
          if (accessToken && refreshToken) {
            const { data, error } = await supabase.auth.setSession({
              access_token: accessToken,
              refresh_token: refreshToken
            });
            if (error) throw error;
            session = data.session;
          }
        }

        if (!session) {
          throw new Error('No valid session found');
        }

        // Get user details
        const { data: { user }, error: userError } = await supabase.auth.getUser();
        if (userError) throw userError;
        if (!user) throw new Error('No user found');

        // Create or get company profile
        const { error: companyError } = await supabase
          .from('companies')
          .insert({
            name: user.user_metadata.company_name,
            user_id: user.id
          })
          .select()
          .single();

        if (companyError) {
          // If the company already exists, that's fine - continue to dashboard
          if (!companyError.message.includes('duplicate')) {
            throw companyError;
          }
        }
        
        // Redirect to onboarding
        navigate('/onboarding');
      } catch (err) {
        console.error('Error setting up user profile:', err);
        setError(err instanceof Error ? err.message : 'Failed to complete account setup');
      } finally {
        setIsProcessing(false);
      }
    };

    setupUserProfile();
  }, [navigate, location.hash]);

  return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center px-4">
      <div className="max-w-md w-full text-center">
        <div className="bg-white p-8 rounded-xl shadow-xl relative">
          {error ? (
            <>
              {isProcessing && (
                <div className="absolute inset-0 bg-white/50 flex items-center justify-center">
                  <ArrowPathIcon className="h-8 w-8 text-blue-600 animate-spin" />
                </div>
              )}
              <div className="mb-6 text-red-500">
                <XCircleIcon className="h-12 w-12 mx-auto" />
              </div>
              <h1 className="text-2xl font-bold text-gray-900 mb-4">Setup Error</h1>
              <p className="text-gray-600 mb-6">{error}</p>
              <div className="space-y-4">
                <button
                  onClick={() => window.location.reload()}
                  className="w-full bg-blue-600 text-white py-3 rounded-lg hover:bg-blue-700 transition-colors"
                >
                  Try Again
                </button>
                <button
                  onClick={() => navigate('/login')}
                  className="w-full bg-gray-100 text-gray-700 py-3 rounded-lg hover:bg-gray-200 transition-colors"
                >
                  Back to Login
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="mb-6 text-green-500">
                <CheckCircleIcon className="h-12 w-12 mx-auto" />
              </div>
              <h1 className="text-2xl font-bold text-gray-900 mb-4">
                Email Verified Successfully!
              </h1>
              <p className="text-gray-600 mb-6">Setting up your account...</p>
              <ArrowPathIcon className="h-8 w-8 text-blue-600 animate-spin mx-auto" />
            </>
          )}
        </div>
      </div>
    </div>
  );
}