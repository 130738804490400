import { useRef } from 'react';
import { Hero } from './Hero';
import { Benefits } from './Benefits';
import { HowItWorks } from './HowItWorks';
import { PriceComparison } from './PriceComparison';
import { Testimonials } from './Testimonials';
import { Pricing } from './Pricing';
import { Link } from 'react-router-dom';

export function HomePage() {
  const pricingRef = useRef<HTMLDivElement>(null);

  const scrollToForm = () => {
    if (pricingRef.current) {
      const yOffset = -100; // Offset to account for fixed header
      const y = pricingRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 text-gray-100">
      <Hero scrollToForm={scrollToForm} />
      <Benefits scrollToForm={scrollToForm} />
      <HowItWorks scrollToForm={scrollToForm} />
      <PriceComparison scrollToForm={scrollToForm} />
      <Testimonials />
      <div ref={pricingRef}>
        <Pricing />
      </div>

      <footer className="bg-gray-900 text-gray-100 py-12">
        <div className="container mx-auto px-6">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="h-8">
              <img src="/gravy-logo.svg" alt="Gravy" className="h-full brightness-0 invert" />
            </div>
            <div className="flex gap-8">
              <Link to="/contact" className="hover:text-gray-300">Contact</Link>
              <Link to="/privacy" className="hover:text-gray-300">Privacy</Link>
              <Link to="/terms" className="hover:text-gray-300">Terms</Link>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}