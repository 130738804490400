import { useState, useCallback } from 'react';
import {
  XMarkIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import type { PriceComparisonItem } from './types';
import {
  historicalPriceData,
  marketPriceData,
  volumeByMonthData,
} from './data';

interface ProductDetailsModalProps {
  product: PriceComparisonItem;
  onClose: () => void;
}

export function ProductDetailsModal({ product, onClose }: ProductDetailsModalProps) {
  const [activeTab, setActiveTab] = useState<'comparison' | 'history'>('comparison');
  
  const historicalData = historicalPriceData[product.name as keyof typeof historicalPriceData] || [];
  const marketData = marketPriceData[product.name as keyof typeof marketPriceData] || [];
  const volumeData = volumeByMonthData[product.name as keyof typeof volumeByMonthData] || [];

  const combinedPriceData = historicalData.map((item, index) => ({
    date: item.date,
    'Your Price': item.price,
    'Market Price': marketData[index].price,
  }));

  // Memoize tooltip content to prevent unnecessary re-renders
  const renderTooltipContent = useCallback((value: number) => {
    return `£${value.toLocaleString()}`;
  }, []);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-4xl max-h-[90vh] overflow-y-auto">
        {/* Header */}
        <div className="border-b border-gray-200 px-6 py-4 flex items-center justify-between">
          <div>
            <h2 className="text-xl font-semibold text-gray-900">{product.name}</h2>
            <div className="mt-1 flex items-center gap-4">
              <p className="text-sm text-gray-500">
                You Pay: £{product.yourPrice.toFixed(2)}
              </p>
              <p className="text-sm text-gray-500">
                Total Spend: £{product.totalSpend.toFixed(2)}
              </p>
            </div>
          </div>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500 transition-colors"
          >
            <XMarkIcon className="h-6 w-6" />
          </button>
        </div>

        {/* Tabs */}
        <div className="border-b border-gray-200">
          <nav className="px-6 -mb-px flex space-x-8">
            <button
              onClick={() => setActiveTab('comparison')}
              className={`py-4 px-1 border-b-2 font-medium text-sm ${
                activeTab === 'comparison'
                  ? 'border-blue-500 text-blue-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Supplier Comparison
            </button>
            <button
              onClick={() => setActiveTab('history')}
              className={`py-4 px-1 border-b-2 font-medium text-sm ${
                activeTab === 'history'
                  ? 'border-blue-500 text-blue-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Price History & Volume
            </button>
          </nav>
        </div>

        {activeTab === 'comparison' ? (
          <>
            {/* Potential Loss Info */}
            <div className="px-6 py-4 bg-red-50">
              <h3 className="text-sm font-medium text-red-800">Potential Loss:</h3>
              <ul className="mt-2 text-sm text-red-700">
                <li>Loss Made: £{Math.abs(product.potentialLoss).toFixed(2)}</li>
                <li>{product.pricePerUnit}</li>
                <li>Difference: {product.difference}</li>
              </ul>
            </div>

            {/* Supplier Comparison Table */}
            <div className="px-6 py-4">
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead>
                    <tr>
                      <th className="px-4 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Description
                      </th>
                      <th className="px-4 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Pack
                      </th>
                      <th className="px-4 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Unit Price
                      </th>
                      <th className="px-4 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Price per g
                      </th>
                      <th className="px-4 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Supplier
                      </th>
                      <th className="px-4 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {product.suppliers.map((supplier, index) => (
                      <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                        <td className="px-4 py-3 text-sm text-gray-900">
                          {supplier.name}
                        </td>
                        <td className="px-4 py-3 text-sm text-gray-900">
                          {supplier.pack}
                        </td>
                        <td className="px-4 py-3 text-sm text-gray-900">
                          £{supplier.unitPrice.toFixed(2)}
                        </td>
                        <td className="px-4 py-3 text-sm text-gray-900">
                          £{supplier.pricePerG.toFixed(4)}
                        </td>
                        <td className="px-4 py-3 text-sm text-gray-900">
                          {supplier.supplier}
                        </td>
                        <td className="px-4 py-3 text-sm">
                          <button className="text-red-600 hover:text-red-800 transition-colors">
                            <TrashIcon className="h-5 w-5" />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        ) : (
          <div className="p-6 space-y-6">
            {/* Price History Chart */}
            <div className="bg-white rounded-lg shadow">
              <h3 className="text-lg font-semibold text-gray-900 px-6 pt-6">Price History</h3>
              <div className="h-80 p-6">
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart 
                    data={combinedPriceData}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis 
                      dataKey="date"
                      tick={{ fill: '#6B7280' }}
                      tickLine={{ stroke: '#6B7280' }}
                    />
                    <YAxis 
                      tick={{ fill: '#6B7280' }}
                      tickLine={{ stroke: '#6B7280' }}
                      tickFormatter={(value) => `£${value}`}
                    />
                    <Tooltip 
                      formatter={renderTooltipContent}
                      contentStyle={{
                        backgroundColor: 'rgba(255, 255, 255, 0.95)',
                        border: '1px solid #E5E7EB',
                        borderRadius: '6px',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                      }}
                    />
                    <Legend />
                    <Line 
                      type="monotone" 
                      dataKey="Your Price" 
                      stroke="#3B82F6" 
                      strokeWidth={2}
                      dot={{ fill: '#3B82F6', r: 4 }}
                      activeDot={{ r: 6 }}
                      isAnimationActive={false}
                    />
                    <Line 
                      type="monotone" 
                      dataKey="Market Price" 
                      stroke="#10B981" 
                      strokeWidth={2}
                      dot={{ fill: '#10B981', r: 4 }}
                      activeDot={{ r: 6 }}
                      isAnimationActive={false}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>

            {/* Purchase Volume Chart */}
            <div className="bg-white rounded-lg shadow">
              <h3 className="text-lg font-semibold text-gray-900 px-6 pt-6">Monthly Purchase Volume</h3>
              <div className="h-80 p-6">
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart 
                    data={volumeData}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis 
                      dataKey="month"
                      tick={{ fill: '#6B7280' }}
                      tickLine={{ stroke: '#6B7280' }}
                    />
                    <YAxis 
                      yAxisId="left" 
                      orientation="left" 
                      stroke="#3B82F6"
                      tick={{ fill: '#6B7280' }}
                      tickLine={{ stroke: '#6B7280' }}
                    />
                    <YAxis 
                      yAxisId="right" 
                      orientation="right" 
                      stroke="#10B981"
                      tick={{ fill: '#6B7280' }}
                      tickLine={{ stroke: '#6B7280' }}
                      tickFormatter={(value) => `£${value}`}
                    />
                    <Tooltip 
                      formatter={renderTooltipContent}
                      contentStyle={{
                        backgroundColor: 'rgba(255, 255, 255, 0.95)',
                        border: '1px solid #E5E7EB',
                        borderRadius: '6px',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                      }}
                    />
                    <Legend />
                    <Bar 
                      yAxisId="left"
                      dataKey="volume" 
                      name="Units Purchased" 
                      fill="#3B82F6"
                      radius={[4, 4, 0, 0]}
                      isAnimationActive={false}
                    />
                    <Bar 
                      yAxisId="right"
                      dataKey="spend" 
                      name="Total Spend (£)" 
                      fill="#10B981"
                      radius={[4, 4, 0, 0]}
                      isAnimationActive={false}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>

            {/* Summary Stats */}
            <div className="grid grid-cols-2 gap-4">
              <div className="bg-blue-50 p-4 rounded-lg">
                <h4 className="text-sm font-medium text-blue-800 mb-2">Volume Trends</h4>
                <p className="text-sm text-blue-600">
                  Average monthly volume: {
                    (volumeData.reduce((acc, curr) => acc + curr.volume, 0) / volumeData.length).toFixed(1)
                  } units
                </p>
                <p className="text-sm text-blue-600">
                  Highest volume: {Math.max(...volumeData.map(d => d.volume))} units
                </p>
              </div>
              <div className="bg-green-50 p-4 rounded-lg">
                <h4 className="text-sm font-medium text-green-800 mb-2">Price Trends</h4>
                <p className="text-sm text-green-600">
                  Price change (4 months): {
                    ((historicalData[historicalData.length - 1].price - historicalData[0].price) / 
                    historicalData[0].price * 100).toFixed(1)
                  }%
                </p>
                <p className="text-sm text-green-600">
                  Average price: £{
                    (historicalData.reduce((acc, curr) => acc + curr.price, 0) / historicalData.length).toFixed(2)
                  }
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}