import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { CheckCircleIcon, ArrowRightIcon, ClockIcon } from '@heroicons/react/24/outline';

export default function ThankYou() {
  useEffect(() => {
    // Scroll to top
    window.scrollTo(0, 0);

    // Fire LinkedIn conversion tracking
    if (window.lintrk) {
      window.lintrk('track', { conversion_id: 20131706 });
    }
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 flex items-center justify-center px-4 py-12">
      <div className="max-w-2xl w-full text-center">
        <div className="mb-8 flex justify-center">
          <div className="bg-accent-success/20 p-4 rounded-full">
            <CheckCircleIcon className="h-16 w-16 text-accent-success" />
          </div>
        </div>

        <h1 className="text-4xl font-bold text-gray-100 mb-4">
          Thank You for Choosing Gravy!
        </h1>
        
        <p className="text-xl text-gray-300 mb-8">
          We've received your submission and our team is already working on it.
        </p>

        <div className="bg-gray-800/50 backdrop-blur-sm p-8 rounded-xl border border-gray-700 mb-8">
          <h2 className="text-2xl font-semibold text-gray-100 mb-4">What Happens Next?</h2>
          <div className="space-y-4 text-left">
            <div className="flex items-start gap-3">
              <div className="flex-shrink-0 mt-1">
                <div className="w-6 h-6 rounded-full bg-accent-primary/20 flex items-center justify-center">
                  <span className="text-accent-primary font-medium">1</span>
                </div>
              </div>
              <p className="text-gray-300">
                Our AI will analyse your invoice data to identify potential savings opportunities.
              </p>
            </div>
            <div className="flex items-start gap-3">
              <div className="flex-shrink-0 mt-1">
                <div className="w-6 h-6 rounded-full bg-accent-primary/20 flex items-center justify-center">
                  <span className="text-accent-primary font-medium">2</span>
                </div>
              </div>
              <p className="text-gray-300">
                Our expert team will review the analysis to ensure accuracy and identify additional opportunities.
              </p>
            </div>
            <div className="flex items-start gap-3">
              <div className="flex-shrink-0 mt-1">
                <div className="w-6 h-6 rounded-full bg-accent-primary/20 flex items-center justify-center">
                  <span className="text-accent-primary font-medium">3</span>
                </div>
              </div>
              <p className="text-gray-300">
                We'll send you a detailed report with actionable insights.
              </p>
            </div>
          </div>

          <div className="mt-6 flex items-center justify-center gap-2 text-accent-primary animate-pulse">
            <ClockIcon className="h-5 w-5" />
            <span className="font-medium">Analysis in progress, current wait time 3-4 hours</span>
          </div>
        </div>

        <Link 
          to="/"
          className="inline-flex items-center gap-2 bg-accent-primary text-white px-8 py-3 rounded-lg hover:bg-accent-primary/90 transition-all"
        >
          Return to Homepage
          <ArrowRightIcon className="h-5 w-5" />
        </Link>
      </div>
    </div>
  );
}