import { useState } from 'react';
import {
  HandThumbDownIcon,
  HandThumbUpIcon,
} from '@heroicons/react/24/outline';
import { ProductDetailsModal } from './ProductDetailsModal';
import { priceComparisonData } from './data';
import type { PriceComparisonItem } from './types';

export function PriceComparisonView() {
  const [selectedProduct, setSelectedProduct] = useState<PriceComparisonItem | null>(null);
  const totalSpend = priceComparisonData.reduce((acc, item) => acc + (item.quantity * item.yourPrice), 0);
  const bestPossibleSpend = priceComparisonData.reduce((acc, item) => acc + (item.quantity * item.marketPrice), 0);
  const potentialSavings = totalSpend - bestPossibleSpend;

  return (
    <div className="space-y-6 animate-fade-in">
      {/* Summary Cards */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="glass-card rounded-xl p-6 text-center transform hover:scale-105 transition-all">
          <p className="text-sm font-medium text-gray-500 mb-2">Total Spend</p>
          <p className="text-3xl font-bold gradient-text">£{totalSpend.toFixed(2)}</p>
        </div>
        <div className="glass-card rounded-xl p-6 text-center transform hover:scale-105 transition-all">
          <p className="text-sm font-medium text-gray-500 mb-2">Best Possible Spend</p>
          <p className="text-3xl font-bold text-green-500">£{bestPossibleSpend.toFixed(2)}</p>
        </div>
        <div className="glass-card rounded-xl p-6 text-center transform hover:scale-105 transition-all">
          <p className="text-sm font-medium text-gray-500 mb-2">Potential Savings</p>
          <p className="text-3xl font-bold text-red-500">£{potentialSavings.toFixed(2)}</p>
        </div>
      </div>

      {/* Price Comparison List */}
      <div className="glass-card rounded-xl overflow-hidden">
        <div className="p-6">
          <h2 className="text-xl font-semibold gradient-text mb-6">Price Comparison Details</h2>
          <div className="space-y-4">
            {priceComparisonData.map((item) => (
              <div 
                key={item.id} 
                className="flex items-center gap-4 p-4 bg-white/50 rounded-xl transition-all hover:shadow-lg hover:transform hover:scale-[1.01]"
              >
                {item.status === 'high' ? (
                  <div className="p-3 bg-gradient-to-br from-red-50 to-red-100 rounded-xl">
                    <HandThumbDownIcon className="h-6 w-6 text-red-500 animated-icon" />
                  </div>
                ) : (
                  <div className="p-3 bg-gradient-to-br from-green-50 to-green-100 rounded-xl">
                    <HandThumbUpIcon className="h-6 w-6 text-green-500 animated-icon" />
                  </div>
                )}
                <div className="flex-grow">
                  <div className="flex justify-between items-start">
                    <div>
                      <h3 className="font-medium text-gray-900">{item.name}</h3>
                      <p className="text-sm text-gray-500">
                        Your Price: £{item.yourPrice.toFixed(2)} | Market Price: £{item.marketPrice.toFixed(2)} | Quantity: {item.quantity}
                      </p>
                    </div>
                    <button 
                      onClick={() => setSelectedProduct(item)}
                      className="primary-button px-4 py-2 rounded-lg text-sm text-white font-medium"
                    >
                      View Details
                    </button>
                  </div>
                  {item.status === 'high' && (
                    <p className="text-sm text-red-500 mt-1">
                      Potential Loss: £{Math.abs(item.potentialLoss).toFixed(2)}
                    </p>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Product Details Modal */}
      {selectedProduct && (
        <ProductDetailsModal 
          product={selectedProduct} 
          onClose={() => setSelectedProduct(null)} 
        />
      )}
    </div>
  );
}