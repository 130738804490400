import { Link } from 'react-router-dom';
import { UserPlusIcon } from '@heroicons/react/24/outline';

const HERO_IMAGE = {
  src: 'https://images.unsplash.com/photo-1552566626-52f8b828add9',
  width: 1920,
  height: 1080,
  alt: 'Restaurant owner in kitchen',
  params: 'auto=format&fit=crop&q=80&w=1920&h=1080&fm=webp'
};

interface HeroProps {
  scrollToForm: () => void;
}

export function Hero({ scrollToForm }: HeroProps) {
  return (
    <header className="relative h-screen min-h-[600px] max-h-[800px]">
      <div className="absolute inset-0">
        <img 
          src={`${HERO_IMAGE.src}?${HERO_IMAGE.params}`}
          alt={HERO_IMAGE.alt}
          width={HERO_IMAGE.width}
          height={HERO_IMAGE.height}
          className="w-full h-full object-cover"
          loading="eager"
          decoding="async"
        />
        <div className="absolute inset-0 bg-gradient-to-b from-black/70 to-black/50"></div>
      </div>

      <nav className="relative container mx-auto px-6 py-6">
        <div className="flex items-center justify-between">
          <div className="w-24 sm:w-32 md:w-36">
            <img src="/gravy-logo.svg" alt="Gravy" className="w-full h-auto brightness-0 invert" />
          </div>
          <div className="flex gap-4 items-center">
            <Link
              to="/signup"
              className="bg-accent-primary text-white px-4 py-2 rounded-lg font-medium transition-all duration-200 flex items-center gap-2 hover:bg-accent-primary/90 hover:scale-105 active:scale-95"
            >
              <UserPlusIcon className="h-5 w-5" />
              Sign Up
            </Link>
            <Link
              to="/login"
              className="bg-accent-primary/10 hover:bg-accent-primary/20 text-accent-primary px-4 py-2 rounded-lg font-medium transition-all duration-200 border border-accent-primary/20 hover:border-accent-primary/40 flex items-center gap-2 hover:scale-105 active:scale-95"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                <path fillRule="evenodd" d="M3 4.25A2.25 2.25 0 015.25 2h5.5A2.25 2.25 0 0113 4.25v2a.75.75 0 01-1.5 0v-2a.75.75 0 00-.75-.75h-5.5a.75.75 0 00-.75.75v11.5c0 .414.336.75.75.75h5.5a.75.75 0 00.75-.75v-2a.75.75 0 011.5 0v2A2.25 2.25 0 0110.75 18h-5.5A2.25 2.25 0 013 15.75V4.25z" clipRule="evenodd" />
                <path fillRule="evenodd" d="M6 10a.75.75 0 01.75-.75h9.546l-1.048-.943a.75.75 0 111.004-1.114l2.5 2.25a.75.75 0 010 1.114l-2.5 2.25a.75.75 0 11-1.004-1.114l1.048-.943H6.75A.75.75 0 016 10z" clipRule="evenodd" />
              </svg>
              Login
            </Link>
          </div>
        </div>
      </nav>

      <div className="relative container mx-auto px-6 h-full pt-16 sm:pt-0">
        <div className="flex items-center h-full">
          <div className="max-w-2xl">
            <div className="bg-black/50 p-6 sm:p-8 rounded-lg backdrop-blur-sm">
              <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold text-gray-100 mb-4 sm:mb-8">
                Improve Your Margins — Stop Overpaying for Fresh Produce
              </h1>
              <p className="text-base sm:text-lg md:text-xl mb-8 sm:mb-12 text-gray-300">
                Instant price checks on fruit & veg supplier invoices. No effort, no spreadsheets—just savings.
              </p>
              <div className="flex flex-col sm:flex-row gap-4">
                <button 
                  onClick={scrollToForm}
                  className="try-it-free-button w-full sm:w-auto px-6 sm:px-8 py-3 sm:py-4 rounded-lg text-base sm:text-lg font-semibold text-white"
                >
                  Try for Free
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}