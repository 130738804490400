import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../lib/supabase';
import { EnvelopeIcon, ArrowUpTrayIcon, ClipboardIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import { PriceComparisonPreview } from './PriceComparisonPreview';

export default function CompanyOnboarding() {
  const navigate = useNavigate();
  const [companyId, setCompanyId] = useState<string | null>(null);
  const [emailAddress, setEmailAddress] = useState<string>('inbox@trygravy.com');
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const getCompanyDetails = async () => {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) {
          navigate('/login');
          return;
        }

        const { data: company } = await supabase
          .from('companies')
          .select('id, code')
          .eq('user_id', user.id)
          .limit(1)
          .maybeSingle();

        if (company) {
          setCompanyId(company.id);
          setEmailAddress(`inbox+${company.code}@trygravy.com`);
        }
      } catch (error) {
        if (error instanceof Error) {
          console.error('Error fetching company details:', error.message);
        }
        // If there's an error, redirect to login
        navigate('/login');
      }
    };

    getCompanyDetails();
  }, [navigate]);

  const copyEmailToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(emailAddress);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      const fileExt = file.name.split('.').pop();
      const fileName = `${companyId}/${Math.random().toString(36).slice(2)}.${fileExt}`;
      
      const { error: uploadError } = await supabase.storage
        .from('invoices')
        .upload(fileName, file);

      if (uploadError) throw uploadError;

      const { data: { publicUrl } } = supabase.storage
        .from('invoices')
        .getPublicUrl(fileName);

      const { error: submissionError } = await supabase
        .from('submissions')
        .insert([{ invoice_url: publicUrl }]);

      if (submissionError) throw submissionError;

      // Redirect to dashboard with initial view set to 'prices'
      navigate('/dashboard', { state: { initialView: 'prices' } });
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('Failed to upload file. Please try again.');
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center px-4 py-12">
      <div className="max-w-2xl w-full">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Let's Get Started!
          </h1>
          <p className="text-xl text-gray-600">
            Upload your first invoice to see potential savings
          </p>
        </div>

        <div className="space-y-8">
          {/* Upload and Email Options */}
          {/* Upload Option */}
          <div className="bg-white p-8 rounded-xl shadow-lg border border-gray-100">
            <div className="flex items-center gap-4 mb-6">
              <div className="p-3 bg-blue-500/10 rounded-lg">
                <ArrowUpTrayIcon className="h-6 w-6 text-blue-500" />
              </div>
              <div>
                <h2 className="text-xl font-semibold text-gray-900">Upload Now</h2>
                <p className="text-gray-600">Get instant analysis of your prices</p>
              </div>
            </div>

            <label className="block w-full p-4 bg-gray-50 border-2 border-dashed border-gray-300 rounded-lg hover:border-blue-500/50 hover:bg-blue-50 transition-all cursor-pointer">
              <input
                type="file"
                className="hidden"
                onChange={handleFileUpload}
                accept=".pdf,.csv,.xlsx,image/*"
              />
              <div className="text-center">
                <p className="text-gray-700">
                  <span className="text-blue-500">Click to upload</span> or drag and drop
                </p>
                <p className="text-sm text-gray-600 mt-1">
                  PDF, CSV, Excel or image files
                </p>
              </div>
            </label>
          </div>

          {/* Email Option */}
          <div className="bg-white p-8 rounded-xl shadow-lg border border-gray-100">
            <div className="flex items-center gap-4 mb-6">
              <div className="p-3 bg-green-500/10 rounded-lg">
                <EnvelopeIcon className="h-6 w-6 text-green-500" />
              </div>
              <div>
                <h2 className="text-xl font-semibold text-gray-900">Forward by Email</h2>
                <p className="text-gray-600">Send invoices directly from your inbox</p>
              </div>
            </div>

            <div className="space-y-3">
              <div className="flex items-center gap-2">
                <input
                  type="text"
                  value={emailAddress}
                  readOnly
                  className="flex-grow px-4 py-3 bg-gray-50 border border-gray-200 text-gray-900 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent"
                />
                <button
                  onClick={copyEmailToClipboard}
                  className="flex items-center gap-2 px-4 py-3 bg-green-500/10 text-green-500 rounded-lg hover:bg-green-500/20 transition-colors"
                >
                  <ClipboardIcon className="h-5 w-5" />
                  {copied ? 'Copied!' : 'Copy'}
                </button>
              </div>
              <p className="text-sm text-gray-600">
                Forward your supplier invoices to this email address. We'll process them automatically.
              </p>
            </div>
          </div>

          {/* Preview Section */}
          <div className="bg-white p-8 rounded-xl shadow-lg border border-gray-100">
            <div className="text-center mb-8">
              <h2 className="text-2xl font-bold text-gray-900 mb-2">
                Preview Your Price Comparison Dashboard
              </h2>
              <p className="text-gray-600">
                See how Gravy helps you identify savings opportunities
              </p>
            </div>

            <PriceComparisonPreview />

            {/* Tooltip Section */}
            <div className="mt-8 bg-blue-50 p-4 rounded-lg flex items-start gap-3">
              <InformationCircleIcon className="h-6 w-6 text-blue-500 flex-shrink-0" />
              <div>
                <p className="text-sm text-blue-900 font-medium">This is a preview using sample data</p>
                <p className="text-sm text-blue-700 mt-1">
                  Upload your first invoice to see your actual data and start identifying savings opportunities.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}