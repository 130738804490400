interface TestimonialImage {
  src: string;
  width: number;
  height: number;
  params: string;
}

interface TestimonialCardProps {
  image: TestimonialImage;
  quote: string;
  author: string;
  role: string;
}

const testimonialImages = [
  {
    src: 'https://images.unsplash.com/photo-1512484776495-a09d92e87c3b',
    width: 800,
    height: 600,
    params: 'auto=format&fit=crop&q=80&w=800&h=600&fm=webp'
  },
  {
    src: 'https://images.unsplash.com/photo-1583394293214-28ded15ee548',
    width: 800,
    height: 600,
    params: 'auto=format&fit=crop&q=80&w=800&h=600&fm=webp&crop=top'
  },
  {
    src: 'https://images.unsplash.com/photo-1581349485608-9469926a8e5e',
    width: 800,
    height: 600,
    params: 'auto=format&fit=crop&q=80&w=800&h=600&fm=webp'
  }
];

function TestimonialCard({ image, quote, author, role }: TestimonialCardProps) {
  return (
    <div className="bg-gray-700/80 rounded-xl overflow-hidden shadow-lg h-full">
      <div className="relative pb-[56.25%]">
        <img 
          src={`${image.src}?${image.params}`}
          alt={author}
          width={image.width}
          height={image.height}
          className="absolute inset-0 w-full h-full object-cover"
          loading="eager"
          decoding="async"
        />
      </div>
      <div className="p-8">
        <p className="text-lg italic text-gray-300 mb-6">{quote}</p>
        <div>
          <p className="font-semibold text-gray-100">{author}</p>
          <p className="text-sm text-gray-400">{role}</p>
        </div>
      </div>
    </div>
  );
}

export function Testimonials() {
  const testimonials = [
    {
      image: testimonialImages[0],
      quote: "Gravy saved us £2,300 in the first month alone—without changing suppliers.",
      author: "James Wilson",
      role: "Restaurant Owner"
    },
    {
      image: testimonialImages[1],
      quote: "I used to spend hours checking invoices. Now Gravy does it automatically.",
      author: "Marcus Rodriguez",
      role: "Executive Chef"
    },
    {
      image: testimonialImages[2],
      quote: "We've reduced food costs by 14% with Gravy. No extra work, just results.",
      author: "Thomas Anderson",
      role: "F&B Director"
    }
  ];

  return (
    <section className="py-20 bg-gray-800">
      <div className="container mx-auto px-6">
        <div>
          <h2 className="text-3xl font-bold text-center text-gray-100 mb-16">
            Who's Using Gravy?
          </h2>
          <p className="text-xl text-center text-gray-300 mb-12">
            Operations Directors & Restaurant Owners
          </p>
          <div className="grid md:grid-cols-3 gap-8">
            {testimonials.map((testimonial, index) => (
              <TestimonialCard
                key={index}
                {...testimonial}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}