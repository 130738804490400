interface TopItem {
  name: string;
  spend: number;
  quantity?: number;
}

interface TopListsProps {
  topSuppliers: TopItem[];
  topProducts: TopItem[];
}

export function TopLists({ topSuppliers, topProducts }: TopListsProps) {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
      <div className="bg-white p-6 rounded-lg shadow">
        <h3 className="text-lg font-semibold text-gray-900 mb-4">Top Suppliers by Spend</h3>
        <div className="space-y-4">
          {topSuppliers.map((supplier, index) => (
            <div key={index} className="flex items-center justify-between">
              <span className="text-gray-600">{supplier.name}</span>
              <span className="font-medium">£{supplier.spend.toLocaleString()}</span>
            </div>
          ))}
        </div>
      </div>

      <div className="bg-white p-6 rounded-lg shadow">
        <h3 className="text-lg font-semibold text-gray-900 mb-4">Top Products by Spend</h3>
        <div className="space-y-4">
          {topProducts.map((product, index) => (
            <div key={index} className="flex items-center justify-between">
              <span className="text-gray-600">{product.name}</span>
              <div className="text-right">
                <div className="font-medium">£{product.spend.toLocaleString()}</div>
                {product.quantity && (
                  <div className="text-sm text-gray-500">{product.quantity} units</div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}