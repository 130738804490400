import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line
} from 'recharts';
import { useCallback, memo } from 'react';

interface OverviewChartsProps {
  monthlySpendData: Array<{
    month: string;
    thisMonth: number;
    lastMonth: number;
  }>;
  weeklySpendData: Array<{
    week: string;
    spend: number;
  }>;
}

// Memoize the entire component
export const OverviewCharts = memo(function OverviewCharts({ monthlySpendData, weeklySpendData }: OverviewChartsProps) {
  // Memoize tooltip content
  const renderTooltipContent = useCallback((value: number) => {
    return `£${value.toLocaleString()}`;
  }, []);

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
      {/* Monthly Spend Comparison */}
      <div className="bg-white p-6 rounded-lg shadow">
        <h3 className="text-lg font-semibold text-gray-900 mb-4">Monthly Spend Comparison</h3>
        <div className="h-80">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart 
              data={monthlySpendData}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis 
                dataKey="month"
                tick={{ fill: '#6B7280' }}
                tickLine={{ stroke: '#6B7280' }}
              />
              <YAxis 
                tick={{ fill: '#6B7280' }}
                tickLine={{ stroke: '#6B7280' }}
                tickFormatter={(value) => `£${value}`}
              />
              <Tooltip 
                formatter={renderTooltipContent}
                contentStyle={{
                  backgroundColor: 'rgba(255, 255, 255, 0.95)',
                  border: '1px solid #E5E7EB',
                  borderRadius: '6px',
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                }}
              />
              <Legend />
              <Bar 
                dataKey="thisMonth" 
                name="This Month" 
                fill="#3B82F6"
                radius={[4, 4, 0, 0]}
                isAnimationActive={false}
              />
              <Bar 
                dataKey="lastMonth" 
                name="Last Month" 
                fill="#93C5FD"
                radius={[4, 4, 0, 0]}
                isAnimationActive={false}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Weekly Spend Trend */}
      <div className="bg-white p-6 rounded-lg shadow">
        <h3 className="text-lg font-semibold text-gray-900 mb-4">Weekly Spend Trend</h3>
        <div className="h-80">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart 
              data={weeklySpendData}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis 
                dataKey="week"
                tick={{ fill: '#6B7280' }}
                tickLine={{ stroke: '#6B7280' }}
              />
              <YAxis 
                tick={{ fill: '#6B7280' }}
                tickLine={{ stroke: '#6B7280' }}
                tickFormatter={(value) => `£${value}`}
              />
              <Tooltip 
                formatter={renderTooltipContent}
                contentStyle={{
                  backgroundColor: 'rgba(255, 255, 255, 0.95)',
                  border: '1px solid #E5E7EB',
                  borderRadius: '6px',
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                }}
              />
              <Line 
                type="monotone" 
                dataKey="spend" 
                stroke="#3B82F6"
                strokeWidth={2}
                dot={{ fill: '#3B82F6', r: 4 }}
                activeDot={{ r: 6 }}
                isAnimationActive={false}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
});