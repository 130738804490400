import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  ArrowRightIcon, 
  BuildingOfficeIcon, 
  EnvelopeIcon, 
  KeyIcon, 
  ChartBarIcon, 
  DocumentTextIcon, 
  SparklesIcon,
  BanknotesIcon,
  ArrowTrendingUpIcon
} from '@heroicons/react/24/outline';
import { supabase } from '../lib/supabase';
import EmailVerification from './EmailVerification';

enum SignUpStep {
  Credentials,
  CompanyInfo,
  Welcome,
  Verification
}

function SignUp() {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(SignUpStep.Credentials);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [retryTimeout, setRetryTimeout] = useState<number | null>(null);

  const handleSignUp = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    
    if (retryTimeout !== null) {
      setError(`Please wait ${retryTimeout} seconds before trying again`);
      return;
    }

    if (!email || !password || !companyName) {
      setError('Please fill in all required fields');
      return;
    }

    setIsLoading(true);

    try {
      const { data: { user, session }, error: signUpError } = await supabase.auth.signUp({
        email,
        password,
        options: {
          data: {
            company_name: companyName
          }
        }
      });

      if (signUpError) {
        if (signUpError.status === 429) {
          const waitTime = 6;
          setRetryTimeout(waitTime);
          
          const interval = setInterval(() => {
            setRetryTimeout(prev => {
              if (prev === null || prev <= 1) {
                clearInterval(interval);
                return null;
              }
              return prev - 1;
            });
          }, 1000);

          throw new Error('Please wait a few seconds before trying again');
        }
        throw signUpError;
      }

      // Check if email confirmation is required
      if (!user || user.identities?.length === 0) {
        throw new Error('No user data returned from signup');
      }

      // If email confirmation is not required, create company and redirect
      if (session) {
        try {
          // Create company profile
          const { error: companyError } = await supabase
            .from('companies')
            .insert([{
              name: companyName,
              user_id: user.id
            }])
            .select()
            .single();

          if (companyError && !companyError.message.includes('duplicate')) {
            throw companyError;
          }

          // Redirect to onboarding
          navigate('/onboarding');
          return;
        } catch (err) {
          console.error('Error creating company:', err);
          throw new Error('Failed to create company profile');
        }
      }

      // If we reach here, email confirmation is required
      setCurrentStep(SignUpStep.Verification);

    } catch (err) {
      console.error('Error during signup:', err);
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError('An unexpected error occurred during signup. Please try again.');
      }
      setIsLoading(false);
    }
  };

  // If we're in verification step, show the verification component
  if (currentStep === SignUpStep.Verification) {
    return <EmailVerification email={email} />;
  }

  const renderStep = () => {
    switch (currentStep) {
      case SignUpStep.Credentials:
        return (
          <div className="space-y-4">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                Email Address
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <EnvelopeIcon className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full pl-10 pr-4 py-2 bg-white border border-gray-300 text-gray-900 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  required
                />
              </div>
            </div>
            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
                Password
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <KeyIcon className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full pl-10 pr-4 py-2 bg-white border border-gray-300 text-gray-900 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  required
                  minLength={6}
                />
              </div>
              <p className="mt-1 text-sm text-gray-500">
                Password must be at least 6 characters
              </p>
            </div>
            <button
              type="button"
              onClick={() => {
                if (email && password.length >= 6) {
                  setCurrentStep(SignUpStep.CompanyInfo);
                  setError('');
                } else {
                  setError('Please fill in all required fields correctly');
                }
              }}
              disabled={!email || password.length < 6}
              className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700 transition-colors flex items-center justify-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Next
              <ArrowRightIcon className="h-5 w-5" />
            </button>
          </div>
        );

      case SignUpStep.CompanyInfo:
        return (
          <div className="space-y-4">
            <div>
              <label htmlFor="companyName" className="block text-sm font-medium text-gray-700 mb-1">
                Company Name
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <BuildingOfficeIcon className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="text"
                  id="companyName"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  className="w-full pl-10 pr-4 py-2 bg-white border border-gray-300 text-gray-900 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  required
                />
              </div>
            </div>
            <button
              type="button"
              onClick={() => {
                if (companyName.trim()) {
                  setCurrentStep(SignUpStep.Welcome);
                  setError('');
                } else {
                  setError('Please enter your company name');
                }
              }}
              disabled={!companyName.trim()}
              className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700 transition-colors flex items-center justify-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Next
              <ArrowRightIcon className="h-5 w-5" />
            </button>
          </div>
        );

      case SignUpStep.Welcome:
        return (
          <div className="space-y-8">
            <div className="text-center">
              <div className="inline-flex items-center justify-center p-4 bg-blue-100 rounded-full mb-4">
                <SparklesIcon className="h-8 w-8 text-blue-600" />
              </div>
              <h2 className="text-2xl font-bold text-gray-900 mb-2">
                Welcome to Gravy, {companyName}!
              </h2>
              <p className="text-gray-600">
                Your dashboard is almost ready. Here's what you'll get access to:
              </p>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div className="col-span-2 bg-gradient-to-br from-blue-50 to-blue-100 p-4 rounded-xl border border-blue-200">
                <div className="flex items-center gap-3 mb-2">
                  <BanknotesIcon className="h-6 w-6 text-blue-600" />
                  <h3 className="font-semibold text-gray-900">Cost Analysis</h3>
                </div>
                <p className="text-gray-600 text-sm">
                  Track spending trends and identify cost-saving opportunities across all your suppliers
                </p>
              </div>

              <div className="bg-gradient-to-br from-green-50 to-green-100 p-4 rounded-xl border border-green-200">
                <div className="flex items-center gap-3 mb-2">
                  <ChartBarIcon className="h-6 w-6 text-green-600" />
                  <h3 className="font-semibold text-gray-900">Price Insights</h3>
                </div>
                <p className="text-gray-600 text-sm">
                  Compare your prices against market rates
                </p>
              </div>

              <div className="bg-gradient-to-br from-purple-50 to-purple-100 p-4 rounded-xl border border-purple-200">
                <div className="flex items-center gap-3 mb-2">
                  <ArrowTrendingUpIcon className="h-6 w-6 text-purple-600" />
                  <h3 className="font-semibold text-gray-900">Market Trends</h3>
                </div>
                <p className="text-gray-600 text-sm">
                  Stay informed about price movements
                </p>
              </div>

              <div className="col-span-2 bg-gradient-to-br from-amber-50 to-amber-100 p-4 rounded-xl border border-amber-200">
                <div className="flex items-center gap-3 mb-2">
                  <DocumentTextIcon className="h-6 w-6 text-amber-600" />
                  <h3 className="font-semibold text-gray-900">Smart Invoice Analysis</h3>
                </div>
                <p className="text-gray-600 text-sm">
                  Upload invoices for instant analysis and savings recommendations
                </p>
              </div>
            </div>

            <div className="pt-4">
              <button
                type="submit"
                disabled={isLoading || retryTimeout !== null}
                className="w-full bg-gradient-to-r from-blue-600 to-blue-700 text-white py-3 rounded-lg hover:opacity-90 transition-all flex items-center justify-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isLoading ? 'Creating Your Account...' : 'Complete Setup'}
                <ArrowRightIcon className="h-5 w-5" />
              </button>
              <p className="text-sm text-center text-gray-500 mt-3">
                We'll send you a verification email to complete your account setup
              </p>
            </div>
          </div>
        );
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center px-4">
      <div className="max-w-md w-full">
        <div className="text-center mb-8">
          <h1 className="text-3xl font-bold text-gray-900 mb-2">Create Your Account</h1>
          <p className="text-gray-600">Start saving on your produce costs today</p>
        </div>

        <div className="bg-white p-8 rounded-xl shadow-xl">
          {error && (
            <div className="mb-4 p-4 bg-red-50 border border-red-500 rounded-lg text-red-600">
              {error}
            </div>
          )}

          <form onSubmit={handleSignUp} className="space-y-6">
            {renderStep()}
          </form>
        </div>
      </div>
    </div>
  );
}

export default SignUp;