import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HomePage } from './components/home/HomePage';
import Login from './components/Login';
import SignUp from './components/SignUp';
import Dashboard from './components/Dashboard';
import Contact from './components/Contact';
import Privacy from './components/Privacy';
import Terms from './components/Terms';
import ThankYou from './components/ThankYou';
import CompanyOnboarding from './components/CompanyOnboarding';
import VerifySuccess from './components/VerifySuccess';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/pricing" element={<HomePage />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/thankyou" element={<ThankYou />} />
        <Route path="/onboarding" element={<CompanyOnboarding />} />
        <Route path="/verify-success" element={<VerifySuccess />} />
      </Routes>
    </Router>
  );
}

export default App;