import React, { useState, useMemo } from 'react';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ArrowsUpDownIcon,
  FunnelIcon,
  CalendarIcon,
  ChartBarIcon,
  BuildingStorefrontIcon,
  BellAlertIcon,
  ShoppingBagIcon,
  ArrowTrendingUpIcon,
  CurrencyPoundIcon
} from '@heroicons/react/24/outline';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import type { PriceComparisonItem } from './dashboard/types';
import { priceComparisonData, historicalPriceData, marketPriceData } from './dashboard/data';

type SortField = 'name' | 'yourPrice' | 'marketPrice' | 'difference';
type SortOrder = 'asc' | 'desc';
type TimeRange = '1M' | '3M' | '6M' | '1Y';
type FilterStatus = 'all' | 'high' | 'good';

interface ExpandedProduct extends PriceComparisonItem {
  isExpanded: boolean;
}

export function PriceComparisonPreview() {
  const [expandedProducts, setExpandedProducts] = useState<ExpandedProduct[]>(
    priceComparisonData.map(product => ({ ...product, isExpanded: false }))
  );
  const [sortField, setSortField] = useState<SortField>('difference');
  const [sortOrder, setSortOrder] = useState<SortOrder>('desc');
  const [timeRange, setTimeRange] = useState<TimeRange>('3M');
  const [filterStatus, setFilterStatus] = useState<FilterStatus>('all');

  // Calculate summary metrics
  const summaryMetrics = useMemo(() => {
    const totalSpend = priceComparisonData.reduce((sum, item) => sum + item.totalSpend, 0);
    const potentialSavings = priceComparisonData.reduce((sum, item) => sum + Math.abs(item.potentialLoss), 0);
    const itemsAboveMarket = priceComparisonData.filter(item => item.status === 'high').length;
    return { totalSpend, potentialSavings, itemsAboveMarket };
  }, []);

  const toggleExpand = (id: number) => {
    setExpandedProducts(products =>
      products.map(product =>
        product.id === id ? { ...product, isExpanded: !product.isExpanded } : product
      )
    );
  };

  const handleSort = (field: SortField) => {
    if (sortField === field) {
      setSortOrder(order => (order === 'asc' ? 'desc' : 'asc'));
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  };

  const sortedProducts = useMemo(() => {
    let filtered = expandedProducts;
    
    if (filterStatus !== 'all') {
      filtered = filtered.filter(product => product.status === filterStatus);
    }

    return [...filtered].sort((a, b) => {
      let comparison = 0;
      switch (sortField) {
        case 'name':
          comparison = a.name.localeCompare(b.name);
          break;
        case 'yourPrice':
          comparison = a.yourPrice - b.yourPrice;
          break;
        case 'marketPrice':
          comparison = a.marketPrice - b.marketPrice;
          break;
        case 'difference':
          comparison = (a.yourPrice - a.marketPrice) - (b.yourPrice - b.marketPrice);
          break;
      }
      return sortOrder === 'asc' ? comparison : -comparison;
    });
  }, [expandedProducts, sortField, sortOrder, filterStatus]);

  const renderHistoricalChart = (product: PriceComparisonItem) => {
    const historicalData = historicalPriceData[product.name];
    const marketData = marketPriceData[product.name];

    if (!historicalData || !marketData) return null;

    const combinedData = historicalData.map((item, index) => ({
      date: item.date,
      'Your Price': item.price,
      'Market Price': marketData[index].price,
    }));

    return (
      <div className="mt-4 bg-gray-50 p-4 rounded-lg">
        <h4 className="text-sm font-medium text-gray-700 mb-4">Price History</h4>
        <div className="h-64">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={combinedData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="Your Price"
                stroke="#3B82F6"
                strokeWidth={2}
                dot={{ fill: '#3B82F6' }}
              />
              <Line
                type="monotone"
                dataKey="Market Price"
                stroke="#10B981"
                strokeWidth={2}
                dot={{ fill: '#10B981' }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
  };

  return (
    <div className="bg-white rounded-lg shadow-lg">
      {/* Summary Cards */}
      <div className="p-6 border-b border-gray-200">
        <div className="grid grid-cols-3 gap-6">
          <div className="bg-gradient-to-br from-blue-50 to-blue-100 p-4 rounded-xl">
            <div className="flex items-center gap-3 mb-2">
              <div className="p-2 bg-blue-500/10 rounded-lg">
                <CurrencyPoundIcon className="h-5 w-5 text-blue-600" />
              </div>
              <h3 className="text-sm font-medium text-gray-700">Monthly Spend</h3>
            </div>
            <p className="text-2xl font-bold text-gray-900">£{summaryMetrics.totalSpend.toLocaleString()}</p>
          </div>
          
          <div className="bg-gradient-to-br from-red-50 to-red-100 p-4 rounded-xl">
            <div className="flex items-center gap-3 mb-2">
              <div className="p-2 bg-red-500/10 rounded-lg">
                <ArrowTrendingUpIcon className="h-5 w-5 text-red-600" />
              </div>
              <h3 className="text-sm font-medium text-gray-700">Potential Savings</h3>
            </div>
            <p className="text-2xl font-bold text-gray-900">£{summaryMetrics.potentialSavings.toLocaleString()}</p>
          </div>
          
          <div className="bg-gradient-to-br from-amber-50 to-amber-100 p-4 rounded-xl">
            <div className="flex items-center gap-3 mb-2">
              <div className="p-2 bg-amber-500/10 rounded-lg">
                <ShoppingBagIcon className="h-5 w-5 text-amber-600" />
              </div>
              <h3 className="text-sm font-medium text-gray-700">Items Above Market</h3>
            </div>
            <p className="text-2xl font-bold text-gray-900">{summaryMetrics.itemsAboveMarket}</p>
          </div>
        </div>
      </div>

      {/* Controls */}
      <div className="p-4 border-b border-gray-200">
        <div className="flex items-center justify-between gap-4">
          <div className="flex items-center gap-2">
            <FunnelIcon className="h-5 w-5 text-gray-400" />
            <select
              value={filterStatus}
              onChange={(e) => setFilterStatus(e.target.value as FilterStatus)}
              className="text-sm border-gray-300 rounded-md"
            >
              <option value="all">All Items</option>
              <option value="high">Above Market</option>
              <option value="good">At/Below Market</option>
            </select>
          </div>
          <div className="flex items-center gap-2">
            <CalendarIcon className="h-5 w-5 text-gray-400" />
            <div className="flex rounded-md overflow-hidden">
              {(['1M', '3M', '6M', '1Y'] as TimeRange[]).map((range) => (
                <button
                  key={range}
                  onClick={() => setTimeRange(range)}
                  className={`px-3 py-1 text-sm ${
                    timeRange === range
                      ? 'bg-blue-500 text-white'
                      : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                  }`}
                >
                  {range}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Table */}
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-4 py-3 text-left">
                <button
                  onClick={() => handleSort('name')}
                  className="flex items-center gap-1 text-xs font-medium text-gray-500 uppercase"
                >
                  Item
                  <ArrowsUpDownIcon className="h-4 w-4" />
                </button>
              </th>
              <th className="px-4 py-3 text-right">
                <button
                  onClick={() => handleSort('yourPrice')}
                  className="flex items-center gap-1 text-xs font-medium text-gray-500 uppercase ml-auto"
                >
                  Your Price
                  <ArrowsUpDownIcon className="h-4 w-4" />
                </button>
              </th>
              <th className="px-4 py-3 text-right">
                <button
                  onClick={() => handleSort('marketPrice')}
                  className="flex items-center gap-1 text-xs font-medium text-gray-500 uppercase ml-auto"
                >
                  Market Price
                  <ArrowsUpDownIcon className="h-4 w-4" />
                </button>
              </th>
              <th className="px-4 py-3 text-right">
                <button
                  onClick={() => handleSort('difference')}
                  className="flex items-center gap-1 text-xs font-medium text-gray-500 uppercase ml-auto"
                >
                  Difference
                  <ArrowsUpDownIcon className="h-4 w-4" />
                </button>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {sortedProducts.map((product) => (
              <React.Fragment key={product.id}>
                <tr 
                  className={`hover:bg-gray-50 cursor-pointer ${
                    product.isExpanded ? 'bg-gray-50' : ''
                  }`}
                  onClick={() => toggleExpand(product.id)}
                >
                  <td className="px-4 py-3">
                    <div className="flex items-center gap-2">
                      {product.isExpanded ? (
                        <ChevronUpIcon className="h-5 w-5 text-gray-400" />
                      ) : (
                        <ChevronDownIcon className="h-5 w-5 text-gray-400" />
                      )}
                      <span className="text-sm text-gray-900">{product.name}</span>
                    </div>
                  </td>
                  <td className="px-4 py-3 text-sm text-right text-gray-900">
                    £{product.yourPrice.toFixed(2)}
                  </td>
                  <td className="px-4 py-3 text-sm text-right text-gray-900">
                    £{product.marketPrice.toFixed(2)}
                  </td>
                  <td className={`px-4 py-3 text-sm text-right font-medium ${
                    product.status === 'high' ? 'text-red-600' : 'text-green-600'
                  }`}>
                    {product.difference}
                  </td>
                </tr>
                {product.isExpanded && (
                  <tr>
                    <td colSpan={4} className="px-4 py-4 bg-gray-50">
                      <div className="space-y-4">
                        {/* Supplier Comparison */}
                        <div>
                          <h4 className="text-sm font-medium text-gray-700 mb-2 flex items-center gap-2">
                            <BuildingStorefrontIcon className="h-5 w-5 text-gray-500" />
                            Supplier Comparison
                          </h4>
                          <div className="bg-white rounded-lg border border-gray-200">
                            <table className="w-full">
                              <thead className="bg-gray-50">
                                <tr>
                                  <th className="px-3 py-2 text-left text-xs font-medium text-gray-500">Supplier</th>
                                  <th className="px-3 py-2 text-left text-xs font-medium text-gray-500">Pack</th>
                                  <th className="px-3 py-2 text-right text-xs font-medium text-gray-500">Unit Price</th>
                                  <th className="px-3 py-2 text-right text-xs font-medium text-gray-500">Price per g</th>
                                </tr>
                              </thead>
                              <tbody className="divide-y divide-gray-200">
                                {product.suppliers.map((supplier, index) => (
                                  <tr key={index} className="hover:bg-gray-50">
                                    <td className="px-3 py-2 text-sm text-gray-900">{supplier.supplier}</td>
                                    <td className="px-3 py-2 text-sm text-gray-500">{supplier.pack}</td>
                                    <td className="px-3 py-2 text-sm text-right text-gray-900">
                                      £{supplier.unitPrice.toFixed(2)}
                                    </td>
                                    <td className="px-3 py-2 text-sm text-right text-gray-900">
                                      £{supplier.pricePerG.toFixed(4)}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>

                        {/* Price History Chart */}
                        <div>
                          <h4 className="text-sm font-medium text-gray-700 mb-2 flex items-center gap-2">
                            <ChartBarIcon className="h-5 w-5 text-gray-500" /> 
                            Price History
                          </h4>
                          {renderHistoricalChart(product)}
                        </div>

                        {/* Professional Features */}
                        {/* Insights Panel */}
                        <div className="bg-gradient-to-r from-blue-50 to-purple-50 p-4 rounded-lg border border-blue-100">
                          <h4 className="text-sm font-medium text-gray-700 mb-3">
                            Actionable Insights
                          </h4>
                          <div className="space-y-3">
                            {product.status === 'high' && (
                              <div className="flex items-start gap-3 bg-white p-3 rounded-lg border border-gray-200">
                                <BellAlertIcon className="h-5 w-5 text-red-500 flex-shrink-0 mt-0.5" />
                                <div>
                                  <p className="text-sm text-gray-900 font-medium">Price Alert</p>
                                  <p className="text-sm text-gray-600">
                                    You're paying {product.difference} more than the market rate.
                                    Consider negotiating with your current supplier or exploring alternative suppliers.
                                  </p>
                                </div>
                              </div>
                            )}
                            
                            {/* Pack Size Optimization */}
                            {product.suppliers.length > 1 && (
                              <div className="flex items-start gap-3 bg-white p-3 rounded-lg border border-gray-200">
                                <ShoppingBagIcon className="h-5 w-5 text-blue-500 flex-shrink-0 mt-0.5" />
                                <div>
                                  <p className="text-sm text-gray-900 font-medium">Pack Size Optimization</p>
                                  <p className="text-sm text-gray-600">
                                    Switching to {product.suppliers[1].pack} could save you 
                                    £{((product.yourPrice - product.suppliers[1].unitPrice) * product.quantity).toFixed(2)} per month
                                  </p>
                                </div>
                              </div>
                            )}
                            
                            {/* Volume Discount Opportunity */}
                            {product.quantity > 100 && (
                              <div className="flex items-start gap-3 bg-white p-3 rounded-lg border border-gray-200">
                                <ArrowTrendingUpIcon className="h-5 w-5 text-green-500 flex-shrink-0 mt-0.5" />
                                <div>
                                  <p className="text-sm text-gray-900 font-medium">Volume Discount Opportunity</p>
                                  <p className="text-sm text-gray-600">
                                    Your high volume qualifies for bulk pricing. Request a volume discount 
                                    quote from your suppliers.
                                  </p>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}