import type { PriceComparisonItem, HistoricalPriceData, MarketPriceData, VolumeByMonthData } from './types';

export const monthlySpendData = [
  { month: 'Jan', thisMonth: 12450, lastMonth: 11200 },
  { month: 'Feb', thisMonth: 13100, lastMonth: 12450 },
  { month: 'Mar', thisMonth: 12800, lastMonth: 13100 },
  { month: 'Apr', thisMonth: 13500, lastMonth: 12800 },
];

export const topSuppliers = [
  { name: 'Wellocks', spend: 5200 },
  { name: 'Greens Produce', spend: 4100 },
  { name: 'Fresh Direct', spend: 3800 },
  { name: 'JJ Food Service', spend: 2900 },
  { name: 'Brakes', spend: 2100 },
];

export const topProducts = [
  { name: 'Tomatoes', spend: 1200, quantity: 480 },
  { name: 'Potatoes', spend: 980, quantity: 350 },
  { name: 'Onions', spend: 850, quantity: 420 },
  { name: 'Lettuce', spend: 720, quantity: 240 },
  { name: 'Carrots', spend: 650, quantity: 380 },
];

export const weeklySpendData = [
  { week: 'Week 1', spend: 3200 },
  { week: 'Week 2', spend: 3400 },
  { week: 'Week 3', spend: 3100 },
  { week: 'Week 4', spend: 3600 },
];

export const priceIncreases = [
  { item: 'Tomatoes', increase: 15.2 },
  { item: 'Peppers', increase: 12.8 },
  { item: 'Cucumbers', increase: 10.5 },
  { item: 'Lettuce', increase: 9.7 },
  { item: 'Onions', increase: 8.9 },
];

export const priceComparisonData: PriceComparisonItem[] = [
  { 
    id: 1,
    name: 'BEANSPROUT 4kg',
    yourPrice: 6.23,
    quantity: 412,
    marketPrice: 4.89,
    potentialLoss: -552.08,
    status: 'high',
    totalSpend: 2566.76,
    suppliers: [
      { name: 'Beansprout', pack: '1 x 250 g', unitPrice: 0.64, pricePerG: 0.0026, supplier: 'Wellocks' },
      { name: 'Beansprout', pack: '1 x 4 kg', unitPrice: 5.60, pricePerG: 0.0012, supplier: 'Wellocks' },
      { name: 'BEANSPROUT(BOX)', pack: 'Box of 4kg', unitPrice: 6.23, pricePerG: 0.0016, supplier: 'Greens Produce' },
      { name: 'BEANSPROUT(ORDER BY KG)', pack: 'Order by KG', unitPrice: 1.88, pricePerG: 0.0019, supplier: 'Greens Produce' }
    ],
    difference: '-10.1%',
    pricePerUnit: '£0.0016 per g x 4000.0'
  },
  {
    id: 2,
    name: 'KING OYSTER MUSHROOM 1kg',
    yourPrice: 12.99,
    quantity: 112,
    marketPrice: 9.10,
    potentialLoss: -435.68,
    status: 'high',
    totalSpend: 1454.88,
    suppliers: [
      { name: 'King Oyster', pack: '1 x 1 kg', unitPrice: 12.99, pricePerG: 0.0130, supplier: 'Fresh Direct' },
      { name: 'King Oyster Mushroom', pack: '1 x 1 kg', unitPrice: 9.10, pricePerG: 0.0091, supplier: 'JJ Food Service' },
      { name: 'King Oyster Premium', pack: '1 x 1 kg', unitPrice: 11.50, pricePerG: 0.0115, supplier: 'Wellocks' }
    ],
    difference: '-29.9%',
    pricePerUnit: '£0.0130 per g x 1000.0'
  },
  {
    id: 3,
    name: 'CHERRY TOMATOES 3kg',
    yourPrice: 8.50,
    quantity: 322,
    marketPrice: 8.56,
    potentialLoss: 19.32,
    status: 'good',
    totalSpend: 2737,
    suppliers: [
      { name: 'Cherry Tomatoes', pack: '3 x 1 kg', unitPrice: 8.50, pricePerG: 0.0028, supplier: 'Wellocks' },
      { name: 'Cherry Tomatoes Premium', pack: '3 x 1 kg', unitPrice: 8.56, pricePerG: 0.0028, supplier: 'Fresh Direct' },
      { name: 'Cherry Tomatoes Box', pack: '3 kg box', unitPrice: 8.99, pricePerG: 0.0030, supplier: 'Brakes' }
    ],
    difference: '0.7%',
    pricePerUnit: '£0.0028 per g x 3000.0'
  },
  {
    id: 4,
    name: 'BABY SPINACH 1kg',
    yourPrice: 4.99,
    quantity: 56,
    marketPrice: 5.50,
    potentialLoss: 0.51,
    status: 'good',
    totalSpend: 249.50,
    suppliers: [
      { name: 'Baby Spinach', pack: '1 kg bag', unitPrice: 4.99, pricePerG: 0.0050, supplier: 'JJ Food Service' },
      { name: 'Baby Spinach Premium', pack: '1 kg', unitPrice: 5.50, pricePerG: 0.0055, supplier: 'Wellocks' },
      { name: 'Baby Spinach Fresh', pack: '1 kg', unitPrice: 5.25, pricePerG: 0.0053, supplier: 'Fresh Direct' }
    ],
    difference: '+10.2%',
    pricePerUnit: '£0.0050 per g x 1000.0'
  },
  {
    id: 5,
    name: 'MIXED PEPPERS 5kg',
    yourPrice: 15.99,
    quantity: 37,
    marketPrice: 13.50,
    potentialLoss: -92.13,
    status: 'high',
    totalSpend: 591.63,
    suppliers: [
      { name: 'Mixed Peppers', pack: '5 kg box', unitPrice: 15.99, pricePerG: 0.0032, supplier: 'Brakes' },
      { name: 'Mixed Peppers Premium', pack: '5 kg', unitPrice: 13.50, pricePerG: 0.0027, supplier: 'Wellocks' },
      { name: 'Peppers Mixed', pack: '5 x 1 kg', unitPrice: 14.25, pricePerG: 0.0029, supplier: 'Fresh Direct' }
    ],
    difference: '-15.6%',
    pricePerUnit: '£0.0032 per g x 5000.0'
  }
];

export const historicalPriceData: HistoricalPriceData = {
  'BEANSPROUT 4kg': [
    { date: '2024-01', price: 5.80, volume: 24 },
    { date: '2024-02', price: 5.95, volume: 28 },
    { date: '2024-03', price: 6.10, volume: 22 },
    { date: '2024-04', price: 6.23, volume: 26 },
  ],
  'KING OYSTER MUSHROOM 1kg': [
    { date: '2024-01', price: 11.50, volume: 28 },
    { date: '2024-02', price: 12.10, volume: 32 },
    { date: '2024-03', price: 12.50, volume: 30 },
    { date: '2024-04', price: 12.99, volume: 34 },
  ],
  'CHERRY TOMATOES 3kg': [
    { date: '2024-01', price: 8.20, volume: 45 },
    { date: '2024-02', price: 8.35, volume: 48 },
    { date: '2024-03', price: 8.45, volume: 50 },
    { date: '2024-04', price: 8.50, volume: 52 },
  ],
  'BABY SPINACH 1kg': [
    { date: '2024-01', price: 4.50, volume: 42 },
    { date: '2024-02', price: 4.75, volume: 45 },
    { date: '2024-03', price: 4.85, volume: 48 },
    { date: '2024-04', price: 4.99, volume: 50 },
  ],
  'MIXED PEPPERS 5kg': [
    { date: '2024-01', price: 14.50, volume: 35 },
    { date: '2024-02', price: 15.25, volume: 38 },
    { date: '2024-03', price: 15.75, volume: 42 },
    { date: '2024-04', price: 15.99, volume: 45 },
  ]
};

export const marketPriceData: MarketPriceData = {
  'BEANSPROUT 4kg': [
    { date: '2024-01', price: 5.40 },
    { date: '2024-02', price: 5.45 },
    { date: '2024-03', price: 5.50 },
    { date: '2024-04', price: 5.60 },
  ],
  'KING OYSTER MUSHROOM 1kg': [
    { date: '2024-01', price: 8.90 },
    { date: '2024-02', price: 9.00 },
    { date: '2024-03', price: 9.05 },
    { date: '2024-04', price: 9.10 },
  ],
  'CHERRY TOMATOES 3kg': [
    { date: '2024-01', price: 8.25 },
    { date: '2024-02', price: 8.35 },
    { date: '2024-03', price: 8.40 },
    { date: '2024-04', price: 8.45 },
  ],
  'BABY SPINACH 1kg': [
    { date: '2024-01', price: 5.25 },
    { date: '2024-02', price: 5.35 },
    { date: '2024-03', price: 5.45 },
    { date: '2024-04', price: 5.50 },
  ],
  'MIXED PEPPERS 5kg': [
    { date: '2024-01', price: 13.25 },
    { date: '2024-02', price: 13.35 },
    { date: '2024-03', price: 13.45 },
    { date: '2024-04', price: 13.50 },
  ]
};

export const volumeByMonthData: VolumeByMonthData = {
  'BEANSPROUT 4kg': [
    { month: 'Jan', volume: 24, spend: 139.20 },
    { month: 'Feb', volume: 28, spend: 166.60 },
    { month: 'Mar', volume: 22, spend: 134.20 },
    { month: 'Apr', volume: 26, spend: 161.98 },
  ],
  'KING OYSTER MUSHROOM 1kg': [
    { month: 'Jan', volume: 28, spend: 322.00 },
    { month: 'Feb', volume: 32, spend: 387.20 },
    { month: 'Mar', volume: 30, spend: 375.00 },
    { month: 'Apr', volume: 34, spend: 441.66 },
  ],
  'CHERRY TOMATOES 3kg': [
    { month: 'Jan', volume: 45, spend: 369.00 },
    { month: 'Feb', volume: 48, spend: 400.80 },
    { month: 'Mar', volume: 50, spend: 422.50 },
    { month: 'Apr', volume: 52, spend: 442.00 },
  ],
  'BABY SPINACH 1kg': [
    { month: 'Jan', volume: 42, spend: 189.00 },
    { month: 'Feb', volume: 45, spend: 213.75 },
    { month: 'Mar', volume: 48, spend: 232.80 },
    { month: 'Apr', volume: 50, spend: 249.50 },
  ],
  'MIXED PEPPERS 5kg': [
    { month: 'Jan', volume: 35, spend: 507.50 },
    { month: 'Feb', volume: 38, spend: 579.50 },
    { month: 'Mar', volume: 42, spend: 661.50 },
    { month: 'Apr', volume: 45, spend: 719.55 },
  ]
};