import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ShieldCheckIcon, LockClosedIcon } from '@heroicons/react/24/outline';

export default function Privacy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 text-gray-100">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold mb-4">Privacy Policy</h1>
          <p className="text-xl text-gray-300">
            Your privacy is our top priority
          </p>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-12">
          <div className="bg-gray-800/50 p-6 rounded-xl border border-gray-700">
            <div className="flex items-center gap-3 mb-4">
              <ShieldCheckIcon className="h-6 w-6 text-accent-primary" />
              <h3 className="text-lg font-semibold">Data Protection</h3>
            </div>
            <p className="text-gray-300">
              Your business details and personal information will never be shared with any third parties. We maintain strict data isolation and security measures.
            </p>
          </div>
          
          <div className="bg-gray-800/50 p-6 rounded-xl border border-gray-700">
            <div className="flex items-center gap-3 mb-4">
              <LockClosedIcon className="h-6 w-6 text-accent-primary" />
              <h3 className="text-lg font-semibold">Price Anonymisation</h3>
            </div>
            <p className="text-gray-300">
              All pricing information is anonymised before being used for market analysis. Your specific pricing remains confidential and secure.
            </p>
          </div>
        </div>

        <div className="space-y-8">
          <section>
            <h2 className="text-2xl font-semibold mb-4">Introduction</h2>
            <p className="text-gray-300 mb-4">
              At Gravy, we take your privacy seriously. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website and use our services.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">Information We Collect</h2>
            <div className="space-y-4">
              <div>
                <h3 className="text-xl font-medium mb-2">Personal Information</h3>
                <p className="text-gray-300">
                  We collect information that you voluntarily provide when using our services, including:
                </p>
                <ul className="list-disc list-inside text-gray-300 mt-2 space-y-1">
                  <li>Name and contact information</li>
                  <li>Email address</li>
                  <li>Business information</li>
                  <li>Invoice data and supplier information</li>
                </ul>
              </div>
            </div>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">Data Privacy & Anonymisation</h2>
            <div className="space-y-4">
              <p className="text-gray-300">
                We take exceptional measures to protect your data privacy:
              </p>
              <ul className="list-disc list-inside text-gray-300 space-y-2">
                <li>Your business details are never shared with third parties</li>
                <li>All pricing information is anonymised before being used for market analysis</li>
                <li>Supplier relationships and negotiation details remain strictly confidential</li>
                <li>Data is encrypted both in transit and at rest</li>
                <li>Access to raw data is strictly limited to essential personnel</li>
              </ul>
              <div className="bg-accent-primary/10 border border-accent-primary/20 rounded-lg p-4 mt-4">
                <p className="text-accent-primary font-medium">
                  Price Anonymisation Process:
                </p>
                <ul className="list-disc list-inside text-gray-300 mt-2 space-y-1">
                  <li>Individual prices are stripped of identifying information</li>
                  <li>Data is aggregated into market averages</li>
                  <li>Specific business relationships are never disclosed</li>
                  <li>Analysis is performed on anonymised datasets only</li>
                </ul>
              </div>
            </div>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">How We Use Your Information</h2>
            <p className="text-gray-300 mb-4">
              We use the collected information for various purposes, including:
            </p>
            <ul className="list-disc list-inside text-gray-300 space-y-2">
              <li>Providing and maintaining our services</li>
              <li>Analysing invoice data to identify cost-saving opportunities</li>
              <li>Communicating with you about our services</li>
              <li>Improving our website and services</li>
              <li>Complying with legal obligations</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">Data Security</h2>
            <p className="text-gray-300 mb-4">
              We implement appropriate technical and organisational measures to maintain the security of your personal information, including:
            </p>
            <ul className="list-disc list-inside text-gray-300 space-y-2">
              <li>Encryption of data in transit and at rest</li>
              <li>Regular security assessments</li>
              <li>Access controls and authentication</li>
              <li>Secure data storage practices</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">Your Rights</h2>
            <p className="text-gray-300 mb-4">
              You have certain rights regarding your personal information, including:
            </p>
            <ul className="list-disc list-inside text-gray-300 space-y-2">
              <li>Right to access your personal information</li>
              <li>Right to correct inaccurate information</li>
              <li>Right to request deletion of your information</li>
              <li>Right to restrict processing of your information</li>
              <li>Right to data portability</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
            <p className="text-gray-300">
              If you have any questions about this Privacy Policy, please{' '}
              <Link to="/contact" className="text-accent-primary hover:text-accent-primary/90">
                contact us
              </Link>.
            </p>
          </section>
        </div>
      </div>
    </div>
  );
}