import { useState, useEffect, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { 
  DocumentTextIcon,
  BuildingStorefrontIcon,
  ChartBarIcon,
  Bars3Icon,
  XMarkIcon,
  BanknotesIcon,
} from '@heroicons/react/24/outline';

import { StatCard } from './dashboard/StatCard';
import { OverviewCharts } from './dashboard/OverviewCharts';
import { TopLists } from './dashboard/TopLists';
import { PriceAlerts } from './dashboard/PriceAlerts';
import { PriceComparisonView } from './dashboard/PriceComparisonView';
import {
  monthlySpendData,
  topSuppliers,
  topProducts,
  weeklySpendData,
  priceIncreases,
} from './dashboard/data';

interface LocationState {
  initialView?: 'overview' | 'prices';
}

export default function Dashboard() {
  const location = useLocation();
  const { initialView } = (location.state as LocationState) || {};
  const [isUploading, setIsUploading] = useState(false);
  const [activeView, setActiveView] = useState<'overview' | 'prices'>(initialView || 'overview');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // Clear location state once on mount
  useEffect(() => {
    window.history.replaceState({}, document.title);
  }, []);

  // Memoize handlers
  const handleFileUpload = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setIsUploading(true);

    try {
      await new Promise(resolve => setTimeout(resolve, 1500));
      setActiveView('prices');
    } catch (error) {
      console.error('Error processing invoice:', error);
    } finally {
      setIsUploading(false);
    }
  }, []);

  const handleViewChange = useCallback((view: 'overview' | 'prices') => {
    setActiveView(view);
    setIsMobileMenuOpen(false);
  }, []);

  const toggleMobileMenu = useCallback(() => {
    setIsMobileMenuOpen(prev => !prev);
  }, []);

  // Memoize static content
  const navigationContent = useMemo(() => (
    <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
      <button
        onClick={() => handleViewChange('overview')}
        className={`inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium ${
          activeView === 'overview'
            ? 'border-blue-500 text-gray-900'
            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
        }`}
      >
        Overview
      </button>
      <button
        onClick={() => handleViewChange('prices')}
        className={`inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium ${
          activeView === 'prices'
            ? 'border-blue-500 text-gray-900'
            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
        }`}
      >
        Price Comparison
      </button>
    </div>
  ), [activeView, handleViewChange]);

  const mobileMenuContent = useMemo(() => (
    isMobileMenuOpen && (
      <div className="sm:hidden border-t border-gray-200">
        <div className="pt-2 pb-3 space-y-1">
          <button
            onClick={() => handleViewChange('overview')}
            className={`w-full px-4 py-2 text-left text-base font-medium ${
              activeView === 'overview'
                ? 'text-blue-600 bg-blue-50'
                : 'text-gray-600 hover:text-gray-800 hover:bg-gray-50'
            }`}
          >
            Overview
          </button>
          <button
            onClick={() => handleViewChange('prices')}
            className={`w-full px-4 py-2 text-left text-base font-medium ${
              activeView === 'prices'
                ? 'text-blue-600 bg-blue-50'
                : 'text-gray-600 hover:text-gray-800 hover:bg-gray-50'
            }`}
          >
            Price Comparison
          </button>
        </div>
      </div>
    )
  ), [activeView, handleViewChange, isMobileMenuOpen]);

  const overviewContent = useMemo(() => (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <StatCard
          title="Total Spend This Month"
          value={`£${monthlySpendData[monthlySpendData.length - 1].thisMonth.toLocaleString()}`}
          change={5.2}
          icon={<BanknotesIcon className="h-6 w-6 text-blue-500" />}
        />
        <StatCard
          title="Total Invoices Processed"
          value="142"
          change={12.5}
          icon={<DocumentTextIcon className="h-6 w-6 text-blue-500" />}
        />
        <StatCard
          title="Unique Suppliers"
          value="8"
          icon={<BuildingStorefrontIcon className="h-6 w-6 text-blue-500" />}
        />
        <StatCard
          title="Potential Savings"
          value="£4,320"
          icon={<ChartBarIcon className="h-6 w-6 text-blue-500" />}
        />
      </div>

      <div className="chart-container">
        <OverviewCharts
          monthlySpendData={monthlySpendData}
          weeklySpendData={weeklySpendData}
        />
      </div>

      <TopLists
        topSuppliers={topSuppliers}
        topProducts={topProducts}
      />

      <PriceAlerts priceIncreases={priceIncreases} />
    </div>
  ), []);

  return (
    <div className="dashboard-layout">
      <nav className="bg-white shadow-lg flex-none">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex">
              <div className="flex-shrink-0 flex items-center">
                <img src="/gravy-logo.svg" alt="Gravy" className="h-8 w-auto" />
              </div>
              {navigationContent}
            </div>
            <div className="flex items-center">
              <input
                type="file"
                id="invoice-upload"
                className="hidden"
                accept=".pdf,.csv,.xlsx"
                onChange={handleFileUpload}
              />
              <label
                htmlFor="invoice-upload"
                className={`bg-blue-600 text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${
                  isUploading ? 'opacity-75 cursor-wait' : 'cursor-pointer'
                }`}
              >
                {isUploading ? 'Processing...' : 'Upload Invoice'}
              </label>
              <button
                onClick={toggleMobileMenu}
                className="ml-4 sm:hidden"
              >
                {isMobileMenuOpen ? (
                  <XMarkIcon className="h-6 w-6 text-gray-500" />
                ) : (
                  <Bars3Icon className="h-6 w-6 text-gray-500" />
                )}
              </button>
            </div>
          </div>
        </div>

        {mobileMenuContent}
      </nav>

      <main className="dashboard-main">
        <div className="dashboard-content">
          <div className="px-4 sm:px-6 lg:px-8 py-8">
            <div className="max-w-7xl mx-auto">
              {activeView === 'overview' ? overviewContent : <PriceComparisonView />}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}