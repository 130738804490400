import { 
  PresentationChartLineIcon,
  BanknotesIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
  SparklesIcon,
  CheckIcon
} from '@heroicons/react/24/outline';

const priceComparisonData = [
  {
    name: 'Cherry Tomatoes (10kg)',
    yourPrice: 34.50,
    marketPrice: 27.25,
    difference: -4.4,
    potentialSaving: 84.75
  },
  {
    name: 'Mixed Peppers (3kg)',
    yourPrice: 22.99,
    marketPrice: 19.50,
    difference: -15.2,
    potentialSaving: 122.85
  },
  {
    name: 'Baby Spinach (2kg)',
    yourPrice: 16.99,
    marketPrice: 16.25,
    difference: -4.6,
    potentialSaving: 79.50
  },
  {
    name: 'Red Onions (10kg)',
    yourPrice: 18.50,
    marketPrice: 18.75,
    difference: 1.4,
    potentialSaving: 0
  },
  {
    name: 'Carrots (10kg)',
    yourPrice: 24.99,
    marketPrice: 21.50,
    difference: -14.0,
    potentialSaving: 182.15
  },
  {
    name: 'Broccoli (5kg)',
    yourPrice: 19.99,
    marketPrice: 19.25,
    difference: -3.7,
    potentialSaving: 29.90
  },
  {
    name: 'Iceberg Lettuce (box)',
    yourPrice: 12.50,
    marketPrice: 12.75,
    difference: 2.0,
    potentialSaving: 0
  }
];

interface PriceComparisonProps {
  scrollToForm: () => void;
}

export function PriceComparison({ scrollToForm }: PriceComparisonProps) {
  // Calculate summary statistics
  const totalSpend = 4820; // Fixed value as requested
  const totalSavings = 598; // Fixed value as requested
  const itemsAboveMarket = priceComparisonData.filter(item => item.difference < 0).length;

  return (
    <section className="py-20 bg-white">
      <div className="container mx-auto px-6">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold mb-4 text-gray-900">See Your Savings at a Glance</h2>
          <p className="text-gray-600">
            Our intelligent price comparison system instantly shows you where you're overpaying
            and how much you could save.
          </p>
        </div>

        <div className="max-w-4xl mx-auto bg-white rounded-2xl shadow-xl overflow-hidden border border-gray-100">
          <div className="grid grid-cols-3 gap-4 p-6">
            <div className="bg-blue-50 rounded-xl p-4 text-center transform hover:scale-105 transition-transform">
              <div className="text-blue-600 mb-2">
                <PresentationChartLineIcon className="h-6 w-6 mx-auto" />
              </div>
              <div className="text-sm text-gray-600">Total Monthly Spend</div>
              <div className="text-2xl font-bold text-blue-600">£{totalSpend}</div>
            </div>

            <div className="bg-green-50 rounded-xl p-4 text-center transform hover:scale-105 transition-transform">
              <div className="text-green-600 mb-2">
                <BanknotesIcon className="h-6 w-6 mx-auto" />
              </div>
              <div className="text-sm text-gray-600">Potential Savings</div>
              <div className="text-2xl font-bold text-green-600">£{totalSavings}</div>
            </div>

            <div className="bg-amber-50 rounded-xl p-4 text-center transform hover:scale-105 transition-transform">
              <div className="text-amber-600 mb-2">
                <HandThumbDownIcon className="h-6 w-6 mx-auto" />
              </div>
              <div className="text-sm text-gray-600">Items Above Market</div>
              <div className="text-2xl font-bold text-amber-600">{itemsAboveMarket}</div>
            </div>
          </div>

          <div className="px-6 pb-6">
            <div className="bg-gray-50 rounded-xl p-6">
              <h3 className="text-gray-900 font-semibold mb-4">Price Comparison Details</h3>
              <div className="space-y-3">
                {priceComparisonData.map((item, index) => (
                  <div 
                    key={index} 
                    className="flex items-center justify-between py-3 border-b border-gray-200 last:border-0 hover:bg-gray-100 rounded-lg px-4 transition-colors"
                  >
                    <div className="flex items-center gap-3">
                      {item.difference < 0 ? (
                        <div className="p-2 bg-red-100 rounded-full">
                          <HandThumbDownIcon className="h-5 w-5 text-red-500" />
                        </div>
                      ) : (
                        <div className="p-2 bg-green-100 rounded-full">
                          <HandThumbUpIcon className="h-5 w-5 text-green-500" />
                        </div>
                      )}
                      <div>
                        <div className="text-gray-900 font-medium">{item.name}</div>
                        <div className="text-sm text-gray-500">
                          Your Price: £{item.yourPrice.toFixed(2)} | Market: £{item.marketPrice.toFixed(2)}
                        </div>
                      </div>
                    </div>
                    {item.difference < 0 && (
                      <div className="text-right">
                        <div className="text-red-600 font-medium">{item.difference}%</div>
                        <div className="text-sm text-red-500">
                          Potential Saving: -£{item.potentialSaving.toFixed(2)}
                        </div>
                      </div>
                    )}
                    {item.difference >= 0 && (
                      <div className="text-right">
                        <div className="text-green-600 font-medium">+{item.difference}%</div>
                        <div className="text-sm text-green-500">Good price!</div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>

            <div className="mt-12 text-center">
              <div className="relative inline-block group">
                <div className="absolute -inset-1 bg-gradient-to-r from-blue-600 via-blue-500 to-blue-700 rounded-lg blur opacity-30 group-hover:opacity-100 transition duration-1000 group-hover:duration-200 animate-pulse"></div>
                <button
                  onClick={scrollToForm}
                  className="relative flex items-center gap-2 px-8 py-4 bg-gradient-to-r from-blue-600 to-blue-700 rounded-lg transform hover:scale-105 transition-all duration-200 shadow-xl hover:shadow-2xl"
                >
                  <SparklesIcon className="h-5 w-5 text-blue-200 animate-pulse" />
                  <span className="text-lg font-bold bg-clip-text text-transparent bg-gradient-to-r from-white to-blue-100">
                    Try it Free — Upload Your Invoice
                  </span>
                  <SparklesIcon className="h-5 w-5 text-blue-200 animate-pulse" />
                </button>
              </div>
              <div className="mt-4 flex items-center justify-center gap-4 text-gray-500">
                <div className="flex items-center gap-2">
                  <CheckIcon className="h-5 w-5 text-green-500" />
                  <span>No credit card required</span>
                </div>
                <div className="flex items-center gap-2">
                  <CheckIcon className="h-5 w-5 text-green-500" />
                  <span>Instant savings report</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}