// HubSpot API configuration
const HUBSPOT_PORTAL_ID = '6270973';
const HUBSPOT_FORM_GUID = '7c86ce1c-b37f-4f1a-a226-a9d90047a953';
const HUBSPOT_API_URL = `https://api.hsforms.com/submissions/v3/integration/submit/${HUBSPOT_PORTAL_ID}/${HUBSPOT_FORM_GUID}`;

interface HubSpotFormData {
  name: string;
  email: string;
  invoice_url?: string;
  reminderTime?: 'morning' | 'afternoon' | 'nextWeek';
  message?: string;
  type?: 'contact' | 'reminder' | 'invoice';
  position?: string;
}

function formatDateForHubSpot(date: Date): string {
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();
  const hours = date.getHours() % 12 || 12;
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const ampm = date.getHours() >= 12 ? 'PM' : 'AM';

  return `${month}-${day}-${year} ${hours.toString().padStart(2, '0')}:${minutes} ${ampm}`;
}

export async function submitToHubSpot(formData: HubSpotFormData) {
  const fields = [
    {
      name: "firstname",
      value: formData.name.split(' ')[0]
    },
    {
      name: "lastname",
      value: formData.name.split(' ').slice(1).join(' ') || ''
    },
    {
      name: "email",
      value: formData.email
    },
    {
      name: "submission_type",
      value: formData.type || 'invoice'
    }
  ];

  // Add job title if provided
  if (formData.position) {
    fields.push({
      name: "job_title",
      value: formData.position
    });
  }

  // Add message if it's a contact form submission
  if (formData.message) {
    fields.push({
      name: "message",
      value: formData.message
    });
  }

  // Add reminder-specific fields if this is a reminder request
  if (formData.reminderTime) {
    const now = new Date();
    let reminderDate = new Date();
    
    switch (formData.reminderTime) {
      case 'morning':
        reminderDate.setDate(now.getDate() + 1);
        reminderDate.setHours(9, 0, 0, 0);
        break;
      case 'afternoon':
        reminderDate.setDate(now.getDate() + 1);
        reminderDate.setHours(14, 0, 0, 0);
        break;
      case 'nextWeek':
        reminderDate.setDate(now.getDate() + 7);
        reminderDate.setHours(9, 0, 0, 0);
        break;
    }

    fields.push(
      {
        name: "reminder_requested",
        value: "Yes"
      },
      {
        name: "reminder_time",
        value: formatDateForHubSpot(reminderDate)
      },
      {
        name: "reminder_preference",
        value: formData.reminderTime
      }
    );
  } else {
    fields.push({
      name: "reminder_requested",
      value: "No"
    });
  }

  // Add invoice URL if provided
  if (formData.invoice_url) {
    fields.push({
      name: "invoice_url",
      value: formData.invoice_url
    });
  }

  const hutk = document.cookie.match(/hubspotutk=(.*?);/)?.[1];
  
  const payload = {
    submittedAt: Date.now(),
    fields,
    context: {
      pageUri: window.location.href,
      pageName: document.title,
      hutk: hutk || undefined
    },
    legalConsentOptions: {
      consent: {
        consentToProcess: true,
        text: "I agree to allow Gravy to store and process my personal data.",
        communications: [
          {
            value: true,
            subscriptionTypeId: 999,
            text: "I agree to receive marketing communications from Gravy."
          }
        ]
      }
    }
  };

  try {
    const response = await fetch(HUBSPOT_API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    });

    if (!response.ok) {
      const errorData = await response.json();
      console.error('HubSpot API error:', errorData);
      throw new Error('HubSpot submission failed');
    }

    // If this was a reminder request, also store it in Supabase
    if (formData.reminderTime) {
      await storeReminderRequest(formData);
    }

    return true;
  } catch (error) {
    console.error('Error submitting to HubSpot:', error);
    throw error; // Re-throw to handle in the component
  }
}

async function storeReminderRequest(formData: HubSpotFormData) {
  const { supabase } = await import('./supabase');
  
  const now = new Date();
  let reminderDate = new Date();
  
  switch (formData.reminderTime) {
    case 'morning':
      reminderDate.setDate(now.getDate() + 1);
      reminderDate.setHours(9, 0, 0, 0);
      break;
    case 'afternoon':
      reminderDate.setDate(now.getDate() + 1);
      reminderDate.setHours(14, 0, 0, 0);
      break;
    case 'nextWeek':
      reminderDate.setDate(now.getDate() + 7);
      reminderDate.setHours(9, 0, 0, 0);
      break;
  }

  const { error } = await supabase
    .from('reminders')
    .insert([
      {
        name: formData.name,
        email: formData.email,
        reminder_time: reminderDate.toISOString(),
        reminder_type: formData.reminderTime,
        status: 'pending',
        position: formData.position
      }
    ]);

  if (error) {
    console.error('Error storing reminder in Supabase:', error);
  }
}