import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

interface PriceIncrease {
  item: string;
  increase: number;
}

interface PriceAlertsProps {
  priceIncreases: PriceIncrease[];
}

export function PriceAlerts({ priceIncreases }: PriceAlertsProps) {
  return (
    <div className="bg-white p-6 rounded-lg shadow">
      <div className="flex items-center gap-2 mb-4">
        <ExclamationTriangleIcon className="h-6 w-6 text-amber-500" />
        <h3 className="text-lg font-semibold text-gray-900">Flagged Price Increases</h3>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {priceIncreases.map((item, index) => (
          <div key={index} className="bg-amber-50 p-4 rounded-lg">
            <div className="font-medium text-gray-900">{item.item}</div>
            <div className="text-amber-600">+{item.increase}% increase</div>
          </div>
        ))}
      </div>
    </div>
  );
}