import { Link } from 'react-router-dom';
import { CheckIcon } from '@heroicons/react/24/outline';

export function Pricing() {
  const features = {
    free: [
      { name: 'Price check for 3 items', included: true },
      { name: 'Email alerts for significant price movements', included: true },
      { name: 'Overpriced item warnings', included: true },
      { name: 'Basic supplier comparison', included: true },
      { name: 'Interactive dashboard', included: false },
      { name: 'Unlimited price comparisons', included: false },
      { name: 'Negotiation data pack generation', included: false },
      { name: 'Advanced market insights', included: false },
    ],
    pro: [
      { name: 'Price check for unlimited items', included: true },
      { name: 'Email alerts for significant price movements', included: true },
      { name: 'Overpriced item warnings', included: true },
      { name: 'Advanced supplier comparison', included: true },
      { name: 'Interactive dashboard', included: true },
      { name: 'Unlimited price comparisons', included: true },
      { name: 'Negotiation data pack generation', included: true },
      { name: 'Advanced market insights', included: true },
    ]
  };

  return (
    <section className="py-20 bg-gray-900">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-gray-100 mb-6">
            Simple, Transparent Pricing
          </h2>
          <p className="text-xl text-gray-300 max-w-3xl mx-auto">
            Start optimising your produce costs today with our flexible pricing options
          </p>
        </div>

        <div className="grid md:grid-cols-2 gap-8 max-w-5xl mx-auto">
          {/* Free Tier */}
          <div className="bg-gray-800/50 backdrop-blur-sm rounded-2xl p-8 border border-gray-700 relative overflow-hidden">
            <div className="absolute top-0 right-0 bg-accent-primary/10 text-accent-primary px-4 py-2 rounded-bl-xl font-medium">
              Free Forever
            </div>
            
            <h3 className="text-2xl font-bold text-gray-100 mb-2">Free Plan</h3>
            <p className="text-gray-400 mb-6">Perfect for smaller establishments</p>
            
            <div className="text-4xl font-bold text-gray-100 mb-8">
              £0
              <span className="text-lg text-gray-400 font-normal">/month</span>
            </div>

            <div className="space-y-4 mb-8">
              {features.free.map((feature, index) => (
                <div key={index} className="flex items-center gap-3">
                  {feature.included ? (
                    <CheckIcon className="h-5 w-5 text-accent-success flex-shrink-0" />
                  ) : (
                    <span className="h-5 w-5 border border-gray-500 rounded-full flex-shrink-0" />
                  )}
                  <span className={feature.included ? 'text-gray-200' : 'text-gray-500'}>
                    {feature.name}
                  </span>
                </div>
              ))}
            </div>

            <Link
              to="/signup"
              className="block w-full bg-accent-primary/10 text-accent-primary py-3 rounded-lg text-center font-semibold hover:bg-accent-primary/20 transition-colors"
            >
              Try for Free
            </Link>
          </div>

          {/* Pro Tier */}
          <div className="bg-gradient-to-br from-accent-primary/20 to-accent-secondary/20 backdrop-blur-sm rounded-2xl p-8 border border-accent-primary/20 relative overflow-hidden transform hover:scale-[1.02] transition-transform">
            <div className="absolute top-0 right-0 bg-accent-primary text-white px-4 py-2 rounded-bl-xl font-medium">
              Most Popular
            </div>
            
            <h3 className="text-2xl font-bold text-gray-100 mb-2">Professional</h3>
            <p className="text-gray-400 mb-6">For businesses focused on maximising savings</p>
            
            <div className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-accent-primary to-accent-secondary mb-8">
              £29
              <span className="text-lg text-gray-400 font-normal">/site/month</span>
            </div>

            <div className="space-y-4 mb-8">
              {features.pro.map((feature, index) => (
                <div key={index} className="flex items-center gap-3">
                  <CheckIcon className="h-5 w-5 text-accent-success flex-shrink-0" />
                  <span className="text-gray-200">{feature.name}</span>
                </div>
              ))}
            </div>

            <div className="space-y-2">
              <Link
                to="/signup"
                className="block w-full bg-gradient-to-r from-accent-primary to-accent-secondary text-white py-3 rounded-lg text-center font-semibold hover:opacity-90 transition-all"
              >
                Start Free Trial
              </Link>
              <p className="text-sm text-gray-500 text-center">no credit card necessary</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}